import * as React from 'react';
import DefaultAvatar from '../../assets/images/user.svg';
import './userprofile.css';
import { Profile } from '../../services/identity';

interface Props {
  profile: Profile;
  onClick?: () => void;
}

const UserAndAvatar: React.FC<Props> = ({ profile, onClick }: Props) => {

  const { displayName, email, photo } = profile;

  return (
    <div className='user-profile-main' role={ onClick ? 'button' : undefined } onClick={ onClick }>
      <img src={ photo ? ('data:image/jpeg;base64,' + photo) : DefaultAvatar } alt='profile picture' className='user-profile-img' />
      <div className='user-info'>
        <div className='user-name'>{ displayName ?? email }</div>
        { displayName && email ? <div className='user-email'>{ email }</div> : null }
      </div>
    </div>
  );
};

export default UserAndAvatar;
