import * as React from 'react';
import TableHeader from './TableHeader';
import TableHeaderCell from './TableHeaderCell';
import TableBody from './TableBody';
import TableRow from './TableRow';
import TableCell from './TableCell';
import { useTranslation } from 'react-i18next';
import Spinner from '../Spinner';
import Paging from './Paging';

export type RendererProps = {
  children: any;
}

export type ActionsRendererProps = {
  children: any;
  onRefresh?: () => (Promise<any> | void);
}

export type Column = {
  propertyId: string;
  captionKey?: string;
  renderer?: React.FC<RendererProps>;
  rowRenderer?: React.FC<RendererProps>;
  actionsRenderer?: React.FC<ActionsRendererProps>;
  align?: 'right' | 'left';
  className?: string;
}
interface Props {
  keyPropertyId?: string;
  columns: Array<Column>;
  statusPropertyId?: string;
  data: Array<any> | undefined;
  noDataTextKey?: string;
  loading?: boolean;
  rowCount?: number;
  pageSize?: number;
  onRefresh?: () => Promise<any> | void;
}

const Table: React.FC<Props> = ({ keyPropertyId, columns, statusPropertyId, data, noDataTextKey, loading, rowCount, pageSize, onRefresh }) => {

  const { t } = useTranslation();

  return (
    <div className='grid'>
      <table>
        <TableHeader>
          { columns.map(col => <TableHeaderCell key={ col.propertyId } textKey={ col.captionKey } />) }
        </TableHeader>
        <TableBody>
          { data?.map((row, i) =>
            <TableRow key={ keyPropertyId ? row[keyPropertyId] : i } status={ statusPropertyId ? row[statusPropertyId] : undefined }>
              { columns.map(col => {
                const Renderer = col.renderer;
                const RowRenderer = col.rowRenderer;
                const ActionsRenderer = col.actionsRenderer;
                return (
                  <TableCell key={ col.propertyId } align={ col.align } className={ col.className }>
                    { Renderer ?
                      <Renderer>{ row[col.propertyId] }</Renderer>
                      :
                      (RowRenderer ?
                        <RowRenderer>{ row }</RowRenderer>
                        :
                        (ActionsRenderer ? <ActionsRenderer onRefresh={ onRefresh }>{ row }</ActionsRenderer> : row[col.propertyId])
                      )
                    }
                  </TableCell>
                )
              })}
            </TableRow>
          ) }
        </TableBody>
      </table>
      { pageSize && rowCount && data && rowCount > pageSize ? <Paging numberOfPages={ Math.ceil(rowCount / pageSize) } /> : null }
      { data && !data.length && noDataTextKey ? <p>{ t(noDataTextKey) }</p> : null }
      { data && !loading ? null : <div className='spinner-center'><Spinner /></div> }
    </div>
  )
}

export default Table;
