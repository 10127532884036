import * as React from 'react';
import Header from './Header';
import { logoutUrl as lu } from '../../services/service';

interface Props {
  title: string;
  homeUrl?: string;
  logoutUrl?: string;
  fullHeight?: boolean;
  children: React.ReactNode;
}

interface PageContextType {
  setFullHeight?: (full: boolean) => void;
}

export const PageContext: React.Context<PageContextType> = React.createContext<PageContextType>({});

const Page: React.FC<Props> = ({ title, homeUrl, logoutUrl = lu, fullHeight, children }: Props) => {

  const [fullHeightState, setFullHeight] = React.useState(false);

  return (
    <div className={ 'page-content' + (fullHeight || fullHeightState ? ' full' : '') }>
      <Header title={ title } homeUrl={ homeUrl } logoutUrl={ logoutUrl } />
      <div className='content'>
        <PageContext.Provider value={{ setFullHeight }}>
          { children }
        </PageContext.Provider>
      </div>
    </div>
  );
}

export default Page;
