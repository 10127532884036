import { createStore, applyMiddleware, Store } from 'redux';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { reducer } from './redux/reducers/index';

const persistConfig = {
  key: 'vscalladmin',
  storage,
  whitelist : ['general'],
};


const persistedReducer = persistReducer(persistConfig, reducer);
const store = createStore(persistedReducer, applyMiddleware(thunk, createLogger({ collapsed : true })));
const persistor = persistStore(store);

export const getStore = (): Store => store;
export const getPersistor = (): Persistor => persistor;
