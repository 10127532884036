import { getStore } from '../configureStore';
type CancelablePromise<T> = {
  promise: Promise<T>;
  cancel: () => void;
}

const utils = {

  makeCancelable: <T>(promise: Promise<T>): CancelablePromise<T> => {
    let isCanceled = false;

    const wrappedPromise = new Promise<T>((resolve, reject) => {
      promise.then(
        val => {
          if (!isCanceled) resolve(val);
        },
        error => {
          if (!isCanceled) reject(error);
        }
      );
    });

    return ({
      promise: wrappedPromise,
      cancel: (): void => { isCanceled = true; },
    })
  },

  stringSorter: <T>(propertyName: keyof T) => (a: T, b: T): number => {
    const aa = String(a[propertyName]).toLowerCase();
    const bb = String(b[propertyName]).toLowerCase();
    return (aa > bb) ? 1 : ((bb > aa) ? -1 : 0);
  },

  // 0 = Sunday, 1 = Monday ... 6 = Saturday
  dayOfWeek: (date?: Date): number => {
    const d = (date ?? new Date()).getDay();
    return d === 7 ? 0 : (d - 1);
  },

  formatDate: (d: number): string => {
    const lang = getStore().getState().general.lang;
    return new Date(d).toLocaleDateString(lang);
  },

  formatTime: (t: number): string => {
    const lang = getStore().getState().general.lang;
    return new Date(t).toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' });
  },

  formatDateTime: (d: number, showSeconds?: boolean): string => {
    const lang = getStore().getState().general.lang;
    return new Date(d).toLocaleString(lang, { day: 'numeric', month: 'numeric', year: 'numeric', hour: '2-digit', minute: '2-digit', second: showSeconds ? '2-digit' : undefined });
  },

  twoDigits: (val: number): string => val < 10 ? '0' + String(val) : String(val)
}

export const hpUrls = {
  location: '/location/sessions',
  organization: '/organization/sessions',
  attendee: '/attendee/wait',
  room: '/room/wait'
}

export default utils;
