import * as React from 'react';
import utils from '../../utils';
import { RendererProps } from '../table/Table';

const DateTimeWithSecondsRenderer: React.FC<RendererProps> = ({ children }) => {

  const d = children && typeof children === 'string' ? new Date(children) : null;

  return (
    <>
      { d ? utils.formatDateTime(d.getTime(), true) : null }
    </>
  )
}

export default DateTimeWithSecondsRenderer;
