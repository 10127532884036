import * as React from 'react';
import { RendererProps } from '../table/Table';
import LinkToSessionLog from '../LinkToSessionLog';

const StatTableActionsRenderer: React.FC<RendererProps> = ({ children }) => {

  return (
    <LinkToSessionLog sessionGid={ children.sessionGid } />
  )
}

export default StatTableActionsRenderer;
