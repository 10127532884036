import * as React from 'react';
import { Session } from '../../../services/scheduler';
import { Link } from 'react-router-dom';

interface Props {
  children: Session;
}

const RoomRenderer: React.FC<Props> = ({ children }: Props) => {

  return (
    <>
      {
        children.roomLink ?
          <Link to={ children.roomLink } target='_blank'>{ children.room }</Link>
          :
          children.room
      }
    </>
  )
}

export default RoomRenderer;
