import * as React from 'react';
import Button from '../Button';
import scheduler, { Session } from '../../../services/scheduler';
import callGroup from '../../../services/callGroup';
import { Mode } from '../../../redux/reducers/general';
import { State } from '../../../redux/reducers/index';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LinkToSessionLog from '../LinkToSessionLog';

type Action = {
  buttonText: string,
  handler: () => (Promise<unknown> | void)
}

interface Props {
  children: Session;
  onRefresh?: () => Promise<any> | void;
}

const TableActions: React.FC<Props> = ({ children, onRefresh }: Props) => {

  const [loading, setLoading] = React.useState<number | null>(null);
  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);
  const { t } = useTranslation();

  const handleStop = React.useCallback((ls: Session): Promise<unknown> | void => {
    if (mode && window.confirm(t('end-call-confirmation', { prisoner: ls.prisoner, attendee: ls.attendees[0]?.name }))) {
      return callGroup.stopSession(mode, ls.gid);
    }
  }, [mode, t]);

  const handleReject = React.useCallback((ls: Session): Promise<unknown> | void => {
    if (mode && window.confirm(t('cancel-planned-session-confirmation', { prisoner: ls.prisoner, attendee: ls.attendees[0]?.name }))) {
      return scheduler.rejectSession(mode, ls.gid);
    }
  }, [mode, t]);

  const createHandler = React.useCallback((handler: (s: Session) => (Promise<unknown> | void), session: Session) => {
    return () => {
      const p = handler(session);
      if (p) {
        p.finally(onRefresh);
      }
    }
  }, [onRefresh]);

  const mapAction = React.useCallback((ac: string, ls: Session): Action | null => {
    switch(ac) {
      case 'Stop': return { buttonText: t('end-call'), handler: createHandler(handleStop, ls) };
      case 'Reject': return { buttonText: t('cancel-planned-session'), handler: createHandler(handleReject, ls) };
      default: return null;
    }
  }, [t, createHandler, handleStop, handleReject]);

  const actionsWithHandlers = React.useMemo(() => children.op?.map(o => mapAction(o, children)), [mapAction, children]);

  const handleClick = React.useCallback((action: Action, index: number) => {
    const promise = action.handler();
    if (promise) {
      setLoading(index);
      promise.finally(() => setLoading(null));
    }
  }, []);

  return (
    <div className='flex-row'>
      { actionsWithHandlers?.map((a, i) =>
        a ?
          <Button
            key={ i }
            loading={ loading === i }
            onClick={ handleClick.bind(null, a, i) }
          >{ a.buttonText }</Button>
          : null
      )}
      <LinkToSessionLog sessionGid={ children.gid } />
    </div>
  )
}

export default TableActions;
