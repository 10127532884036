import * as React from 'react';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

interface Props {
  onBack: () => void;
  onToday: () => void;
  onForward: () => void;
}

const CalendarControls: React.FC<Props> = ({ onBack, onToday, onForward }: Props) => {

  const { t } = useTranslation();

  return (
    <div className='middle-part'>
      <Button onClick={ onBack }>{ "<<" }</Button>
      <Button onClick={ onToday }>{ t('today') }</Button>
      <Button onClick={ onForward }>{ ">>" }</Button>
    </div>
  );
};

export default CalendarControls;
