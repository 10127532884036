import * as React from 'react';
import Page from '../common/Page';
// import { setMode } from '../../redux/actions/general';
// import { useDispatch, useSelector } from "react-redux";
// import { State } from '../../redux/reducers/index';
import Login from './Login';
import { useTranslation } from 'react-i18next';

const Aad: React.FC = () => {

  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const sessionToken = useSelector<State, string | undefined>(state => state.general?.sessionToken?.attendee?.token);

  // React.useEffect(() => {
  //   dispatch(setMode('attendee'));
  // }, [dispatch]);

  return (
    <Page title={ t('login-header') } homeUrl='/login'>
        <Login />
    </Page>
  );
};

export default Aad;
