import { Reducer } from 'redux';
import { SET_MODE, SET_USERID, GeneralTypes, SET_SESSION_TOKEN, SET_CALL_TOKEN, SET_LOCATION, SET_ORGANIZATION, SET_ORGANIZATION_GID, SET_LOC_ORG_GID, SET_ROOM, SET_ROOMS, REFRESH_SESSION_LIST, SET_LANG, SET_GROUPS, SET_GROUP, SET_IMPERSONATED } from '../actions/general';
import { Room } from '../../services/scheduler';
import { Claim, Profile } from '../../services/identity';
import { getI18n } from 'react-i18next';
import { REHYDRATE } from 'redux-persist';
import { Group } from '../../services/identity';
import utils from '../../screens/utils';

export interface SessionTokenWithExpiration {
  token: string;
  expiration: number;
  name?: string;
  claims?: Array<Claim>;
  callToken?: string;
  callTokenExpiration?: number;
  callTokenGuid?: string;
  profile?: Profile;
}

export interface SessionToken {
  location?: SessionTokenWithExpiration;
  room?: SessionTokenWithExpiration;
  attendee?: SessionTokenWithExpiration
  organization?: SessionTokenWithExpiration
}

export type Mode = 'attendee' | 'room' | 'location' | 'organization';

export interface GeneralState {
  mode: Mode | undefined;
  userId?: string;
  sessionToken: SessionToken;
  location?: string;
  organization?: string;
  organizationGid?: string;
  locOrgGid?: string;
  room?: string;
  rooms?: Array<Room>;
  sessionListUpdated?: number;
  lang?: string;
  groups?: Array<Group>;
  group?: Group | undefined;
  impersonated?: boolean;
}

const initialState: GeneralState = {
  mode: undefined,
  sessionToken: {}
};

export const generalReducer: Reducer<GeneralState, GeneralTypes> = (state = initialState, action: GeneralTypes): GeneralState => {
  switch (action.type) {
    case SET_MODE:
      return { ...state, mode: action.mode };
    case SET_USERID:
      return { ...state, userId: action.userId };
    // case SET_DISPLAY_NAME:
    //   return { ...state, displayName: action.displayName || '' };
    case SET_LOCATION:
      return { ...state, location: action.location };
    case SET_ORGANIZATION:
      return { ...state, organization: action.organization };
    case SET_ORGANIZATION_GID:
      return { ...state, organizationGid: action.organizationGid };
    case SET_LOC_ORG_GID:
      return { ...state, locOrgGid: action.locOrgGid };
    case SET_ROOM:
      return { ...state, room: action.room };
    case SET_ROOMS:
      return { ...state, rooms: action.rooms?.sort(utils.stringSorter<Room>('name')) };
    case SET_SESSION_TOKEN:
      return { ...state, sessionToken: { ...state.sessionToken, [action.tokenType]: {
        ...action.token,
        expiration: action.token?.expiration ? new Date(action.token.expiration).getTime() : undefined,
        profile: action.profile ?? action.token?.profile
      } } };
    case SET_CALL_TOKEN:
      return { ...state, sessionToken: { ...state.sessionToken, [action.tokenType]: { ...state.sessionToken[action.tokenType], callToken: action.token, callTokenExpiration: action.expiration ? new Date(action.expiration).getTime() : undefined, callTokenGuid: action.attendeeGuid } } };
    case REFRESH_SESSION_LIST:
      return { ...state, sessionListUpdated: Date.now() };
    case SET_LANG:
      getI18n().changeLanguage(action.lang);
      return { ...state, lang: action.lang };
    case REHYDRATE:
      if (action.payload?.general?.lang) {
        getI18n().changeLanguage(action.payload?.general?.lang);
      }
      return state;
    case SET_GROUPS:
      return { ...state, groups: action.groups, group: action.group };
    case SET_GROUP:
      return { ...state, group: action.group };
    // case SET_PROFILE:
    //   return { ...state, displayName: action.profile.displayName, email: action.profile.email, lang: action.profile.preferredLanguage?.split('-')[0] ?? state.lang, photo: action.profile.photo ?? undefined };
    case SET_IMPERSONATED:
      return { ...state, impersonated: action.impersonated ?? false };
    default:
      return state;
  }
};
