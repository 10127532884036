import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setRoom } from '../../redux/actions/general';
import { useNavigate } from 'react-router';
import Button from '../common/Button';
import { RendererProps } from '../common/table/Table';

const RoomActionsRenderer: React.FC<RendererProps> = ({ children }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();

  const handleConnect = React.useCallback(() => {
    dispatch(setRoom(children.gid));
    history('/calling');
  }, [children.gid, dispatch, history]);

  return (
    <Button onClick={ handleConnect }>{ t('connect') }</Button>
  )
}

export default RoomActionsRenderer;
