import * as React from 'react';
import { Session } from '../../../services/scheduler';
import { useTranslation } from 'react-i18next';
import { RendererProps } from '../table/Table';

const StatusRenderer: React.FC<RendererProps> = ({ children }) => {

  const { t } = useTranslation();

  const renderStatus = React.useCallback((session: Session) => {
    switch (session.status) {
      case 'APPROVED':
        if (session.schStatus === 'STARTED') {
          return <><strong><span style={{ color: 'orange' }}><div style={{ display: 'inline-block'}} className='rotating'>&#x7f7;</div> { t('in-progress') }</span></strong></>;
        } else {
          return <><strong><span style={{ color: 'green' }}>&#x2714; { t('approved') }</span></strong> <small>- { t('approved-by') } { session.approvedBy }</small></>;
        }
      case 'REJECTED': return <><span style={{ color: 'red' }}>&#x2716; { session.message }</span></>;
      default: return session.message;
    }
  }, [t])

  return (
    <>
      { renderStatus(children) }
    </>
  )
}

export default StatusRenderer;
