import * as React from 'react';
import { HubConnectionBuilder, HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useSelector, useDispatch } from 'react-redux';
import { State } from './redux/reducers/index';
import { refreshSessionList } from './redux/actions/general';
import { SessionToken, Mode } from './redux/reducers/general';

const URL = '/callmanager/cmHub';

interface Props {
  disabled?: boolean;
  useCallToken?: boolean;
}

const SignalR: React.FC<Props> = ({ disabled, useCallToken }) => {

  const tokens: SessionToken = useSelector<State, SessionToken>(state => state.general.sessionToken);
  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);
  const token: string | undefined = React.useMemo(() =>
    mode ?
      (useCallToken ?
        ((tokens[mode]?.callTokenExpiration || 0) > Date.now() ? tokens[mode]?.callToken : undefined)
        :
        ((tokens[mode]?.expiration || 0) > Date.now() ? tokens[mode]?.token : undefined)
      )
      : undefined,
  [mode, tokens, useCallToken]);
  const dispatch = useDispatch();

  const newSessionListener = React.useCallback((conn: HubConnection) => {
    console.log('SignalR zprava ze serveru: NewSession, connectionId:', conn?.connectionId);
    dispatch(refreshSessionList());
  }, [dispatch]);

  const handleDisconnect = React.useCallback((conn: HubConnection): void => {
    if (conn.state === HubConnectionState.Connected) {
      const connId = conn.connectionId;
      console.log('Odpojovani SignalR...');
      // conn.off('SessionOver', sessionOverListener);
      // conn.off('NewSession', newSessionListener);
      conn.stop().then(() => console.log('SignalR odpojeno, connectionId:', connId), err => console.log('Chyba pri odpojovani SignalR', err));
    }
  }, []);

  // const sessionOverListener = React.useCallback((conn: HubConnection) => {
  //   console.log('SignalR zprava ze serveru: SessionOver, connectionId:', conn?.connectionId);
  //   dispatch(endCall());
  //   handleDisconnect(conn);
  // }, [dispatch, handleDisconnect]);

  React.useEffect(() => {
    if (token && !disabled) {
      const conn = new HubConnectionBuilder().withUrl(URL, { accessTokenFactory: () => token }).withAutomaticReconnect().build();
      console.log('Pripojovani SignalR...');
      conn.start().then(
        () => {
          console.log('SignalR pripojeno, connectionId:', conn.connectionId);
          // conn.on('SessionOver', sessionOverListener.bind(null, conn));
          conn.on('NewSession', newSessionListener.bind(null, conn));
        },
        err => console.log('Chyba spojeni SignalR', err)
      );
      return () => handleDisconnect(conn);
    }
  }, [token, disabled, handleDisconnect, newSessionListener]);

  return null;
}

export default SignalR;
