import * as React from 'react';
import Spinner from './Spinner';
import ErrorText from './ErrorText';

interface Props {
  loading?: boolean;
  disabled?: boolean;
  error?: any;
  title?: string;
  children?: string;
  onClick?: () => Promise<any> | void;
}

const Button: React.FC<Props> = ({ loading, disabled, error, title, children, onClick }: Props) => {

  const [loading2, setLoading2] = React.useState(false);
  const l = loading || loading2;

  const handleClick = React.useCallback(() => {
    if (onClick) {
      const p = onClick();
      if (p && p instanceof Promise) {
        setLoading2(true);
        p.finally(() => setLoading2(false));
      }
    }
  }, [onClick]);

  return (
    <>
      <button className={ 'primary' + (error ? ' error' : '') } type={ onClick ? 'button' : 'submit' } title={ title } disabled={ disabled || undefined } onClick={ handleClick }>
        <span style={ l ? { opacity: 0 } : undefined }>{ children }</span>
        { l ? <div className='spinner-wrapper'><Spinner /></div> : null }
      </button>
      { error && typeof error === 'string' ? <ErrorText>{ error }</ErrorText> : null }
    </>
  )
}

export default Button;
