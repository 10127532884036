import * as React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../redux/reducers/index';
import './datetimeinputbox.css';
// @ts-ignore
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import en from 'date-fns/locale/en-GB';
import cs from 'date-fns/locale/cs';
import { useTranslation } from 'react-i18next';
import utils from '../utils';

interface Props {
  id?: string;
  placeholder?: string;
  value?: string;
  minValue?: string;
  maxValue?: string;
  onChange: (value: string) => void;
  onChangeValid?: (valid: boolean) => void;
}

const stringToDateValue = (stringValue: string | undefined): (Date | null) => {
  const d = stringValue?.split?.(':');
  if (d) {
    const h = Number.parseInt(d[0]);
    const m = Number.parseInt(d[1]);
    if (!Number.isNaN(h) && !Number.isNaN(m)) {
      const res = new Date();
      res.setHours(h);
      res.setMinutes(m);
      res.setSeconds(0);
      res.setMilliseconds(0);
      return res;
    }
  }
  return null;
}

const TimeInputBox: React.FC<Props> = ({ id, placeholder, value, minValue, maxValue, onChange, onChangeValid }: Props) => {

  const { t } = useTranslation();
  const [error, setError] = React.useState<string>('');
  const lang = useSelector<State, string | undefined>(state => state.general.lang) ?? 'cs';
  const dateValue = React.useMemo(() => stringToDateValue(value), [value]);
  const minDateValue = React.useMemo(() => stringToDateValue(minValue), [minValue]);
  const maxDateValue = React.useMemo(() => stringToDateValue(maxValue), [maxValue]);

  React.useEffect(() => {
    registerLocale('en', en);
    registerLocale('cs', cs);
    setDefaultLocale('cs');
  }, []);

  React.useEffect(() => {
    onChangeValid?.(!error);
  }, [error, onChangeValid]);

  const handleChangeValue = React.useCallback((value: Date) => {
    if (value) {
      if (minDateValue && value.getTime() < minDateValue.getTime()) {
        setError(t('to-before-from'));
      } else if (maxDateValue && value.getTime() > maxDateValue.getTime()) {
        setError(t('from-after-to'));
      } else {
        setError('');
      }
      const h = value.getHours();
      const m = value.getMinutes();
      onChange(utils.twoDigits(h) + ':' + utils.twoDigits(m));
    }
  }, [maxDateValue, minDateValue, onChange, t]);

  return (
    <DatePicker
        id={ id }
        locale={ lang }
        selected={ dateValue }
        minTime={ minDateValue ?? new Date(0) }
        maxTime={ maxDateValue ?? new Date(Number.MAX_SAFE_INTEGER) }
        showTimeSelect
        showTimeSelectOnly
        dateFormat='p'
        timeIntervals={ 5 }
        className={ error ? 'red-border' : undefined }
        title={ error || undefined }
        placeholder={ placeholder }
        onChange={ handleChangeValue }
      />
  )
}

export default TimeInputBox;
