import * as React from 'react';
import { RendererProps } from '../table/Table';

const StatStatusRenderer: React.FC<RendererProps> = ({ children }) => {

  const renderStatus = React.useCallback((status: string) => {
    switch (status) {
      case 'APPROVED':
        return <strong><span style={{ color: 'green' }}>&#x2714;</span></strong>;
      default:
        return <span style={{ color: 'red' }}>&#x2716;</span>;
    }
  }, [])

  return (
    <>
      { renderStatus(String(children)) }
    </>
  )
}

export default StatStatusRenderer;
