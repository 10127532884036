import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Page from '../common/Page';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../redux/reducers/index';
import { setMode, setRooms } from '../../redux/actions/general';
import utils from '../utils';
import scheduler from '../../services/scheduler';
import { useTranslation } from 'react-i18next';

const Location: React.FC = () => {

  const dispatch = useDispatch();
  const sessionToken = useSelector<State, string | undefined>(state => state.general.sessionToken.location?.token);
  const location = useSelector<State, string | undefined>(state => state.general.location);
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(setMode('location'));
  }, [dispatch]);

  React.useEffect(() => {
    if (sessionToken) {
      const cp = utils.makeCancelable(scheduler.rooms('location'));
      cp.promise.then(
        rs => dispatch(setRooms(rs)),
        () => dispatch(setRooms([]))
      );
      return cp.cancel;
    }
  }, [dispatch, sessionToken])

  return (
    <Page title={ location || t('ou') } homeUrl='/location/sessions'>
      <Outlet />
    </Page>
  );
};

export default Location;
