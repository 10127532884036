import * as React from 'react';
import packagejson from '../../../package.json';
import build from '../../build.json';

const Version: React.FC = () => {

  return (
    <div className='version'>
      { packagejson.version + '.' + build.build }
    </div>
  );
};

export default Version;
