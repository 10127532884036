import * as React from 'react';
import scheduler from '../../services/scheduler';
import { SessionsResponse } from '../../services/scheduler';
import Table from '../common/table/Table';
import { State } from '../../redux/reducers/index';
import { useSelector } from 'react-redux';
import SignalR from '../../SignalR';
import DateTimeRenderer from '../common/renderers/DateTimeRenderer';
import StatusRenderer from '../common/renderers/StatusRenderer';
import RoomRenderer from '../common/renderers/RoomRenderer';
import AttendeeRenderer from '../common/renderers/AttendeeRenderer';
import TableActions from '../common/table/TableActions';
import utils from '../utils';
import HeaderControls from '../common/HeaderControls';
import TableWrapper from '../common/table/TableWrapper';

const SessionListTable: React.FC = () => {

  const [sessions, setSessions] = React.useState<SessionsResponse>();
  const [loading, setLoading] = React.useState(true);
  const sessionListUpdated = useSelector<State, number | undefined>(state => state.general.sessionListUpdated);
  const locOrgGid = useSelector<State, string | undefined>(state => state.general.locOrgGid);

  const fetchSessions = React.useCallback(() => {
    setLoading(true);
    const cp = utils.makeCancelable(scheduler.sessions('organization'));
    cp.promise.then(
      res => {
        setLoading(false);
        setSessions(res);
      },
      () => {
        setLoading(false);
        setSessions([]);
        console.log('chyba pri nacitani udalosti');
      }
    );
    return cp;
  }, []);

  const handleRefresh = React.useCallback(() => {
    return fetchSessions().promise;
  }, [fetchSessions]);

  React.useEffect(() => {
    return fetchSessions().cancel;
  }, [fetchSessions, sessionListUpdated, locOrgGid]);

  return (
    <TableWrapper>
      <SignalR />
      <HeaderControls onRefresh={ handleRefresh } />
      <Table
        columns={ [
          { propertyId: 'startAt', captionKey: 'start', renderer: DateTimeRenderer },
          { propertyId: 'endAt', captionKey: 'end', renderer: DateTimeRenderer },
          { propertyId: 'status', captionKey: 'status', rowRenderer: StatusRenderer },
          { propertyId: 'prisoner', captionKey: 'prisoner' },
          { propertyId: 'location', captionKey: 'ou' },
          { propertyId: 'room', captionKey: 'room', rowRenderer: RoomRenderer },
          { propertyId: 'attendees', captionKey: 'attendee', rowRenderer: AttendeeRenderer },
          { propertyId: 'actions', actionsRenderer: TableActions }
        ] }
        keyPropertyId='gid'
        statusPropertyId='status'
        data={ sessions }
        noDataTextKey='no-planned-sessions'
        loading={ loading }
        onRefresh={ handleRefresh }
      />
    </TableWrapper>
  );
};

export default SessionListTable;
