import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { setSessionToken, setGroups, setMode, setOrganization, setLocation, setLocOrgGid, setLang, setImpersonated } from '../../redux/actions/general';
import identity from "../../services/identity";
import { MsalConfig } from './MsalConfig';
import { PublicClientApplication, AuthenticationResult } from "@azure/msal-browser";
import { useTranslation, getI18n } from 'react-i18next';
import { hpUrls } from '../utils';
import Menu from '../common/Menu';
import MenuItem from '../common/MenuItem';
import Spinner from '../common/Spinner';
import { State } from '../../redux/reducers/index';

const Login: React.FC = () => {

  const [room, setRoom] = React.useState<string | null>(null);
  const [loadingAad, setLoadingAad] = React.useState<boolean>(false);
  const [errorAad, setErrorAad] = React.useState<boolean>(false);
  const impersonated = useSelector<State, boolean | undefined>(state => state.general.impersonated);
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickVS = React.useCallback((room?: string) => {
    setErrorAad(false);
    setLoadingAad(true);
    const clientApp: PublicClientApplication = new PublicClientApplication(MsalConfig);
    clientApp.loginPopup({
      scopes: [],
      prompt: impersonated ? "login" : "select_account"
    }).then(
      (res: AuthenticationResult) => {
        if (res.accessToken) {
          identity.adLogin(res.accessToken, room).then(
            res => {
              setLoadingAad(false);
              dispatch(setImpersonated(false));
              const pr = res?.profile?.preferredLanguage;
              if (pr) {
                const l = pr.split('-')[0];
                if (l && getI18n().languages.includes(l)) {
                  dispatch(setLang(l));
                }
              }
              if (room) {
                dispatch(setMode('room'));
                dispatch(setSessionToken('room', res));
                history('/calling');
              } else {
                const groups = res.groups.filter(g => g.type === 'OJ' || g.type === 'KM');
                const gr = groups?.[0];
                if (gr) {
                  dispatch(setGroups(groups, gr));
                  const mode = gr.type === 'KM' ? 'organization' : 'location';
                  dispatch(setMode(mode));
                  dispatch(setSessionToken(mode, res));
                  dispatch(mode === 'location' ? setLocation(gr.name) : setOrganization(gr.name));
                  dispatch(setLocOrgGid(gr.id));
                  history(hpUrls[mode]);
                } else {
                  dispatch(setGroups([]));
                }
              }
            },
            err => {
              setLoadingAad(false);
              setErrorAad(true);
              console.log(err);
            }
          )
        }
      },
      err => {
        setLoadingAad(false);
        setErrorAad(true);
        console.log(err);
      }
    );
  }, [dispatch, history, impersonated]);

  React.useEffect(() => {
    dispatch(setSessionToken('attendee'));
    dispatch(setSessionToken('room'));
    dispatch(setSessionToken('location'));
    dispatch(setSessionToken('organization'));
    const lang = navigator.language?.split('-')[0];
    if (lang) {
      dispatch(setLang(getI18n().languages.includes(lang) ? lang : 'cs'));
    }
    const rId = qs.parse(location.search).room;
    if (rId) {
      const r = Array.isArray(rId) ? rId[0] : rId;
      handleClickVS(r ?? undefined);
      setRoom(r);
    }
  }, [location.search, dispatch, handleClickVS]);

  return (
    room ?
      <div className='spinner-center'>{ errorAad ? t('aad-login-error') : <Spinner /> }</div>
      :
      <Menu>
        <MenuItem loading={ loadingAad } error={ errorAad } onClick={ handleClickVS }>{ t('ou-cp-login') }</MenuItem>
        {/* <MenuItem href='/attendee'>{ t('attendee') }</MenuItem> */}
        <MenuItem href='/room' grey>{ t('connect-room') }</MenuItem>
        <MenuItem href='/location' grey>{ t('session-planning') }</MenuItem>
        <MenuItem href='/organization' grey>{ t('sessions-overview') }</MenuItem>
      </Menu>
  );
};

export default Login;
