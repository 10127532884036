import * as React from 'react';
import { useNavigate } from 'react-router';
import scheduler, { LocationsResponse } from '../../services/scheduler';
// import { Link } from 'react-router-dom';
import utils from '../utils';
import Form from '../common/Form';
import InputBox from '../common/InputBox';
import Dropdown from "../common/DropDown";
import Button from '../common/Button';
import Heading from '../common/Heading';
import ButtonRow from '../common/ButtonRow';
import { useTranslation } from 'react-i18next';

const dateToDateString = (date: Date | null): string | null => date?.toJSON()?.split('T')?.[0] || null;
const dateStringToDate = (dateString: string | null): Date | null => {
  if (!dateString) return null;
  const d = new Date(dateString);
  if (d) {
    return d;
  } else {
    return dateString && dateString.length ? (new Date(dateString + 'T00:00:00') || null) : null;
  }
}

const CreateSession: React.FC = () => {

  const [loading, setLoading] = React.useState<boolean>(false);
  const [locations, setLocations] = React.useState<LocationsResponse>([]);
  const locationOptions = React.useMemo(() => locations.map(r => ({ key: r.gid, label: r.name, value: r.name })), [locations]);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [startDateString, setStartDateString] = React.useState<string>(dateToDateString(startDate) || '1970-01-01');
  const [startDateValid, setStartDateValid] = React.useState<boolean>(true);
  const hour = new Date().getHours();
  const [startTime, setStartTime] = React.useState<string>(utils.twoDigits(hour >= 23 ? 0 : hour + 1) + ':00');
  const [location, setLocation] = React.useState<string>('');
  const [prisonerName, setPrisonerName] = React.useState<string>('');
  const [attendeeName, setAttendeeName] = React.useState<string>('');
  const history = useNavigate();
  const { t } = useTranslation();

  React.useEffect(() => {
    const cp = utils.makeCancelable(scheduler.locations());
    cp.promise.then(rs => setLocations(rs), () => console.log('chyba pri nacitani lokaci'));
    return cp.cancel;
  }, [])

  const handleChangeStartDate = React.useCallback((value) => {
    if (startDateValid) {
      const d = dateStringToDate(value);
      if (d) {
        const ds = dateToDateString(d);
        if (ds) {
          setStartDate(d);
          setStartDateString(ds);
          setStartDateValid(true);
          return;
        }
      }
    }
    setStartDateString(value);
    setStartDateValid(false);
  }, [startDateValid]);

  const handleBlurStartDate = React.useCallback((value) => {
    const d = dateStringToDate(value);
    if (d) {
      const ds = dateToDateString(d);
      if (ds) {
        setStartDate(d);
        setStartDateString(ds);
        setStartDateValid(true);
        return;
      }
    }
    setStartDateString(value);
    setStartDateValid(false);
  }, []);

  // const handleChangeDuration = React.useCallback((value) => setDuration(Number(value)), []);

  const handleCreate = React.useCallback(() => {
    const startTimeArr = startTime.split(':');
    const hours = Number.parseInt(startTimeArr[0]);
    const minutes = Number.parseInt(startTimeArr[1]);
    const starts = startDate;
    starts.setHours(hours);
    starts.setMinutes(minutes);
    starts.setSeconds(0);
    starts.setMilliseconds(0);
    setLoading(true);
    scheduler.requestSession(prisonerName, starts, attendeeName, location).then(
      () => {
        setLoading(false);
        history('/organization/sessions');
      },
      () => {
        setLoading(false);
        console.log('chyba pri vytvareni udalosti');
      }
    );
  }, [startDate, startTime, prisonerName, attendeeName, location, history]);

  const handleBack = React.useCallback(() => {
    history('/organization/sessions');
  }, [history]);

  const handleChangeLocation = React.useCallback(l => {
    setLocation(l || '');
  }, []);

  return (
    <Form>
      <Heading>{ t('new-session') }</Heading>
      <InputBox label={ t('start-date') } type='date' required placeholder='yyyy-mm-dd' error={ startDateValid ? null : t('invalid-date-format') } value={ startDateString } onChange={ handleChangeStartDate } onBlur={ handleBlurStartDate } />
      <InputBox label={ t('start-time') } type='time' required value={ startTime } onChange={ setStartTime } />
      <Dropdown label={ t('ou') } required options={ locationOptions } placeholder={ t('select-ou') } onChange={ handleChangeLocation } />
      <Heading>{ t('prisoner') }</Heading>
      <InputBox label={ t('name') } required value={ prisonerName } onChange={ setPrisonerName } />
      <Heading>{ t('attendee') }</Heading>
      <InputBox label={ t('name') } required value={ attendeeName } onChange={ setAttendeeName } />
      <ButtonRow>
        <Button loading={ loading } disabled={ !startDate || !startTime || !location || !prisonerName || !attendeeName || loading } onClick={ handleCreate }>{ t('create') }</Button>
        <Button onClick={ handleBack }>{ t('back') }</Button>
      </ButtonRow>
    </Form>
  );
};

export default CreateSession;
