import * as React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../redux/reducers/index';
import './datetimeinputbox.css';
// @ts-ignore
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';

interface Props {
  id?: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  canBeInThePast?: boolean;
  dateOnly?: boolean;
  value?: Date;
  onChange: (value: Date) => void;
  onChangeValid?: (valid: boolean) => void;
  onBlur?: (value: Date) => void;
}

const DateTimeInputBox: React.FC<Props> = ({ id, label, required, disabled, canBeInThePast, dateOnly, value, onChange, onChangeValid, onBlur }: Props) => {

  const { t } = useTranslation();
  const [isInThePast, setInThePast] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const lang = useSelector<State, string | undefined>(state => state.general.lang) ?? 'cs';
  const maxTime = React.useMemo(() => {
    const res = new Date();
    res.setHours(23);
    res.setMinutes(59);
    res.setMilliseconds(999);
    return res;
  }, []);

  React.useEffect(() => {
    const valid = !isInThePast;
    if (valid) {
      setError('');
    }
    onChangeValid?.(valid);
  }, [isInThePast, onChangeValid]);

  const handleChangeValue = React.useCallback(value => {
    if (!canBeInThePast && value.getTime() < Date.now()) {
      setInThePast(true);
      setError(t('start-in-the-past'));
    } else {
      setInThePast(false);
    }
    onChange(value);
  }, [canBeInThePast, onChange, t]);

  const handleBlur = React.useCallback((event) => {
    onBlur?.(event?.target?.value || '');
  }, [onBlur]);

  return (
    <div className='row'>
      { label ? <label htmlFor={ id }>{ label }{ required ? <span style={{ color: 'red' }}> *</span> : null }</label> : null }
      <DatePicker
          id={ id }
          locale={ lang }
          selected={ value }
          showTimeSelect={ !dateOnly }
          timeFormat={ dateOnly ? undefined : 'p' }
          timeIntervals={ 5 }
          dateFormat={ dateOnly ? 'P' : 'Pp' }
          minDate={ canBeInThePast ? undefined : new Date() }
          minTime={ dateOnly ? undefined : (canBeInThePast ? new Date(0) : new Date()) }
          maxTime={ maxTime }
          disabled={ disabled }
          readOnly={ disabled }
          className={ error ? 'red-border' : undefined }
          title={ error || undefined }
          isClearable={ !required }
          required={ required }
          onChange={ handleChangeValue }
          onBlur={ handleBlur }
        />
    </div>
  )
}

export default DateTimeInputBox;
