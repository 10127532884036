import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  textKey?: string;
  children?: React.ReactNode;
}

const TableHeaderCell: React.FC<Props> = ({ textKey, children }: Props) => {

  const { t } = useTranslation();

  return (
    <th className='table-header sorted-none'>
      { textKey ? t(textKey) : children }
    </th>
  )
}

export default TableHeaderCell;
