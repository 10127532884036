import * as React from 'react';

interface Props {
  children?: React.ReactNode;
}

const ButtonRow: React.FC<Props> = ({ children }: Props) => {

  return (
    <div className='row top-margin'>
      { children }
    </div>
  )
}

export default ButtonRow;
