import * as React from 'react';
import { StatsResponse } from '../../../services/scheduler';
import Table from '../table/Table';
import DateTimeRenderer from '../renderers/DateTimeRenderer';
import scheduler from '../../../services/scheduler';
import CalendarHeader from '../calendar/CalendarHeader';
import StatStatusRenderer from '../renderers/StatStatusRenderer';
import utils from '../../utils';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/reducers/index';
import { Mode } from '../../../redux/reducers/general';
import StatTableActionsRenderer from './StatTableActionsRenderer';
import TableWrapper from '../table/TableWrapper';

const StatTable: React.FC = () => {

  const now = new Date();
  const [stats, setStats] = React.useState<StatsResponse>();
  const [loading, setLoading] = React.useState(true);
  const [month, setMonth] = React.useState(now.getMonth() + 1);
  const [year, setYear] = React.useState(now.getFullYear());
  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);

  const fetchStats = React.useCallback(() => {
    setLoading(true);
    const cp = utils.makeCancelable(scheduler.statistics(mode ?? 'location', [year, month, '01'].join('-')));
    cp.promise.then(
      res => {
        setLoading(false);
        setStats(res);
      },
      () => {
        setLoading(false);
        setStats([]);
        console.log('chyba pri nacitani statistiky');
      }
    );
    return cp;
  }, [mode, year, month]);

  React.useEffect(() => {
    return fetchStats().cancel;
  }, [fetchStats]);

  const handleToday = React.useCallback(() => {
    const now = new Date();
    setMonth(now.getMonth() + 1);
    setYear(now.getFullYear());
  }, []);

  const handleBack = React.useCallback(() => {
    setMonth(month > 1 ? month - 1 : 12);
    setYear(month > 1 ? year : year - 1);
  }, [month, year]);

  const handleForward = React.useCallback(() => {
    setMonth(month < 12 ? month + 1 : 1);
    setYear(month < 12 ? year : year + 1);
  }, [month, year]);

  return (
    <TableWrapper>
      <CalendarHeader
        refreshing={ loading }
        onRefresh={ () => fetchStats().promise }
        onToday={ handleToday }
        onBack={ handleBack }
        onForward={ handleForward }
      >
        <div className='left-part'>
          { month } - { year }
        </div>
      </CalendarHeader>
      <Table
        columns={ [
          { propertyId: 'startAt', captionKey: 'start', renderer: DateTimeRenderer },
          { propertyId: 'status', captionKey: 'status', renderer: StatStatusRenderer },
          { propertyId: 'prisoner', captionKey: 'prisoner' },
          { propertyId: 'location', captionKey: 'organizational-unit' },
          { propertyId: 'organization', captionKey: 'contact-point' },
          { propertyId: 'visitor', captionKey: 'attendee' },
          { propertyId: 'actions', actionsRenderer: StatTableActionsRenderer }
        ] }
        keyPropertyId='sessionGid'
        statusPropertyId='status'
        data={ stats }
        noDataTextKey='no-data'
        loading={ loading }
      />
    </TableWrapper>
  );
};

export default StatTable;
