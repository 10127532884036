import * as React from 'react';

interface Props {
  id?: string;
  type?: 'text' | 'password' | 'number' | 'time' | 'date';
  label?: string;
  step?: number;
  required?: boolean;
  disabled?: boolean;
  error?: string | null;
  placeholder?: string;
  autoComplete?: string;
  value?: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
}

const InputBox: React.FC<Props> = ({ id, type = 'text', label, step, required, disabled, error, placeholder, autoComplete = 'off', value, onChange, onBlur }: Props) => {

  const handleChange = React.useCallback((event) => {
    onChange(event?.target?.value || '');
  }, [onChange]);

  const handleBlur = React.useCallback((event) => {
    onBlur?.(event?.target?.value || '');
  }, [onBlur]);

  return (
    <div className='row'>
      { label ? <label htmlFor={ id }>{ label }{ required ? <span style={{ color: 'red' }}> *</span> : null }</label> : null }
      <input
        id={ id }
        name={ id }
        step={ step }
        disabled={ disabled }
        required={ required }
        autoComplete={ autoComplete }
        title={ error || undefined }
        placeholder={ placeholder }
        className={ error ? 'error' : undefined }
        type={ type }
        value={ value }
        onChange={ handleChange }
        onBlur={ handleBlur }
      />
    </div>
  )
}

export default InputBox;
