import * as React from 'react';
import { WorkingHours } from './Calendar';
import HalfHour from './HalfHour';
import { SessionsResponse } from '../../../services/scheduler';

export type TooltipDirection = 'left' | 'right';

interface Props {
  day: number;
  workingHours?: WorkingHours[];
  locationSessions: SessionsResponse | undefined;
  organizationSessions: SessionsResponse | undefined;
  tooltipDirection: TooltipDirection;
  numberOfLocationRooms: number;
  numberOfOrganizationRooms: number;
  onCreate?: (date: Date) => void;
}

const hours = Array(24).fill(0).map((x,i)=>i);

const Day: React.FC<Props> = ({ day, workingHours, locationSessions, organizationSessions, tooltipDirection, numberOfLocationRooms, numberOfOrganizationRooms, onCreate }: Props) => {

  return (
    <div className='day'>
      { hours.map(hour =>
        <div key={ hour } className='hour'>
          <HalfHour
            workingHours={ workingHours}
            locationSessions={ locationSessions }
            organizationSessions={ organizationSessions }
            day={ day }
            hour={ hour }
            tooltipDirection={ tooltipDirection }
            numberOfLocationRooms={ numberOfLocationRooms }
            numberOfOrganizationRooms={ numberOfOrganizationRooms }
            onCreate={ onCreate }
          />
          <HalfHour
            workingHours={ workingHours}
            locationSessions={ locationSessions }
            organizationSessions={ organizationSessions }
            day={ day }
            hour={ hour }
            minute={ 30 }
            tooltipDirection={ tooltipDirection }
            numberOfLocationRooms={ numberOfLocationRooms }
            numberOfOrganizationRooms={ numberOfOrganizationRooms }
            onCreate={ onCreate }
          />
        </div>
      ) }
    </div>
  );
};

export default Day;
