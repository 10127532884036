import * as React from 'react';
import { Session } from '../../../services/scheduler';
import { Link } from 'react-router-dom';

interface Props {
  children: Session;
}

const AttendeeRenderer: React.FC<Props> = ({ children }: Props) => {

  return (
    <>
      { children.attendees.map((a, i) =>
        <React.Fragment key={ a.gid }>
          { a.attendeeLink ?
            <Link key={ a.gid } to={ a.attendeeLink } target='_blank'>{ a.name }</Link>
            :
            a.name
          }
          { i + 1 < children.attendees.length ? ', ' : null }
        </React.Fragment>
      )}
    </>
  )
}

export default AttendeeRenderer;
