import { getStore } from '../configureStore';
import { Mode } from '../redux/reducers/general';
import identity from './identity';
import { setCallToken } from '../redux/actions/general';

const FETCH_TIMEOUT = 20000;

export interface Auth {
  token?: string;
  username?: string;
  password?: string;
}

export interface Init {
  method?: string;
  headers?: Headers;
  redirect?: RedirectType;
  cache?: CacheType;
  credentials?: CredentialsType;
  // eslint-disable-next-line no-undef
  body?: BodyInit;
  addOrganizationId?: boolean;
  timeout?: number;
}

type RedirectType = 'follow' | 'error' | 'manual';
type CacheType = 'default' | 'no-store' | 'reload' | 'no-cache' | 'force-cache' | 'only-if-cached';
type CredentialsType = 'omit' | 'same-origin' | 'include';
// type AtomicParamValue = string | number | boolean | null | undefined;
// type PV = AtomicParamValue | Record<string, AtomicParamValue>;
type ParamValue = any;
export type Params = Record<string, ParamValue> | undefined | null;

export const logoutUrl = '/';

export function utoa(data: string): string {
  return btoa(unescape(encodeURIComponent(data)));
}

function addQueryParam(str: Array<string>, key: string, value: any): Array<string> {
  if (value instanceof Array) {
    value.forEach((el: ParamValue) => (str = addQueryParam(str, key, el)));
  } else if (typeof value === 'object') {
    str.push(encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(value)));
  } else if (value !== undefined) {
    str.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
  }
  return str;
}

function serializeQueryParams(data: Record<string, ParamValue>): string {
  let str: Array<string> = [];
  Object.keys(data).forEach((k) => {
    const value = data[k];
    if (value) {
      str = addQueryParam(str, k, value);
    }
  });
  return str.join('&');
}

function tokenAuthHeaders(headers: Headers, token: string): Headers {
  headers.append('Authorization', 'Bearer ' + token);
  return headers;
}

function addAuthenticationHeaders(headers: Headers = new Headers(), token: string | null): Headers {
  if (token) {
    return tokenAuthHeaders(headers, token);
  }
  return headers;
}

const defaultFetchInit: Init = {
  method: 'GET',
  redirect: 'error',
  cache: 'no-cache',
  credentials: 'same-origin'
};

export class FetchError extends Error {
  fetchErr: unknown;
  errorResp: Response | null | undefined;
  isAuthenticationFailure: boolean;
  isNetworkError: boolean;
  isTimeout: boolean;

  constructor(
    message: string,
    fetchErr?: unknown,
    errorResp?: Response | null,
    isAuthenticationFailure = false,
    isNetworkError = false,
    isTimeout = false
  ) {
    super(message);
    this.fetchErr = fetchErr;
    this.errorResp = errorResp;
    this.isAuthenticationFailure = isAuthenticationFailure;
    this.isNetworkError = isNetworkError;
    this.isTimeout = isTimeout;
  }
}

function checkAuthenticationError(url: string, response: Response): Promise<Response> {
  if (response.status === 403 || response.status === 401) {
    location.assign(logoutUrl);
  }
  return Promise.resolve(response);
}

function checkApiError(response: Response): Promise<Response> {
  if (response.status === 400) {
    const contentType = response.headers.get('content-type');
    if (contentType?.startsWith('application/json') && !response.bodyUsed) {
      return response
        .clone()
        .json()
        .then(
          (errorDesc) => {
            throw new FetchError('api error', errorDesc, response);
          },
          () => response
        );
    }
  }

  return Promise.resolve(response);
}

const timeoutPromise = <T>(url: string, ms: number, promise: Promise<T>): Promise<T> =>
  new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new FetchError(url + ' - fetch timeout expired (' + ms + ' ms)', null, null, false, false, true));
    }, ms);
    promise.then(
      (res) => {
        clearTimeout(timeoutId);
        resolve(res);
      },
      (err) => {
        clearTimeout(timeoutId);
        reject(err);
      }
    );
  });

export function doFetch<R>(
  url: string,
  data?: Params,
  init: Init | null = {},
  token?: string | null
): Promise<R> {
  init = { ...defaultFetchInit, ...init };
  init.headers = addAuthenticationHeaders(init.headers || new Headers(), token || null);

  if (data) {
    if (init.method === 'GET') {
      const params = serializeQueryParams(data);
      if (params && params !== '') {
        url = url + '?' + params;
      }
    } else if (init.method === 'POST' || init.method === 'PUT') {
      init.body = JSON.stringify(data);
      if (init.headers) {
        init.headers.set('content-type', 'application/json');
      }
    }
  }

  return timeoutPromise(
    url,
    init.timeout || FETCH_TIMEOUT,
    fetch(url, init).then(
      (res) => {
        if (res.ok) {
          const contentType = res.headers.get('content-type');
          if (contentType && contentType.startsWith('application/json')) {
            const r = res.clone().json();
            return r;
          } else if (res.status === 204) {
            return "";
          }
          return res;
        } else {
          return checkAuthenticationError(url, res).then(
            () =>
              checkApiError(res).then(() => {
                throw new FetchError(url + ' - invalid response', null, res);
              }),
            (err) => {
              throw err;
            }
          );
        }
      },
      (err) => {
        throw new FetchError(url, err, null, false, true);
      }
    )
  );
}

export function doFetchWithToken<R>(
  tokenType: Mode,
  url: string,
  data?: Params,
  init: Init | null = {}
): Promise<R> {
  const token = getStore().getState().general.sessionToken[tokenType]?.token;
  return doFetch<R>(url, data, init, token);
}

export function doFetchWithCallToken<R>(
  tokenType: Mode,
  attendeeGuid: string,
  forceFetchNewCallToken: boolean,
  url: string,
  data?: Params,
  init: Init | null = {}
): Promise<R> {
  const { getState, dispatch } = getStore();
  const sessionToken = getState().general.sessionToken[tokenType];
  const callToken = sessionToken?.callToken;
  const callTokenExpiration = sessionToken?.callTokenExpiration;
  const callTokenGuid = sessionToken?.callTokenGuid;
  if (!forceFetchNewCallToken && callToken && callTokenExpiration > Date.now() && attendeeGuid === callTokenGuid) {
    return doFetch<R>(url, data, init, callToken);
  } else {
    return identity.impersonateAsAttendee(tokenType, attendeeGuid).then(
      ct => {
        if (ct.token) {
          dispatch(setCallToken(tokenType, ct.token, ct.expiration, attendeeGuid));
          return doFetch<R>(url, data, init, ct.token);
        } else {
          return Promise.reject('no valid token returned by impersonateAsAttendee');
        }
      }
    )
  }
}

export function doFetchWithImpersonateAsSession<R>(
  tokenType: Mode,
  sessionGuid: string,
  url: string,
  data?: Params,
  init: Init | null = {}
): Promise<R> {
  return identity.impersonateAsSession(tokenType, sessionGuid).then(
    ct => {
      if (ct.token) {
        return doFetch<R>(url, data, init, ct.token);
      } else {
        return Promise.reject('no valid token returned by impersonateAsSession');
      }
    }
  )
}

export function dateToJson(date: Date): string {
  return date.toJSON();
}
