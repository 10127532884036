import * as React from 'react';
import { Room } from '../../services/scheduler';
import Table from '../common/table/Table';
import scheduler from '../../services/scheduler';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../redux/reducers/index';
import utils from '../utils';
import { Mode } from '../../redux/reducers/general';
import { setRooms } from '../../redux/actions/general';
import RoomActionsRenderer from './RoomActionsRenderer';
import TableWrapper from '../common/table/TableWrapper';
import HeaderControls from '../common/HeaderControls';

const Rooms: React.FC = () => {

  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);
  const locationGuid = useSelector<State, string | undefined>(state => state.general.locOrgGid);
  const rooms = useSelector<State, Array<Room> | undefined>(state => state.general.rooms);
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();

  const fetchRooms = React.useCallback(() => {
    if (mode) {
      setLoading(true);
      const cp = utils.makeCancelable(scheduler.rooms(mode));
      cp.promise.then(
        res => {
          setLoading(false);
          dispatch(setRooms(res));
        },
        () => {
          setLoading(false);
          dispatch(setRooms([]));
          console.log('chyba pri nacitani mistnosti');
        }
      );
      return cp;
    }
  }, [dispatch, mode]);

  React.useEffect(() => {
    return fetchRooms()?.cancel;
  }, [fetchRooms, locationGuid]);

  return (
    <TableWrapper>
      <HeaderControls refreshing={ loading } onRefresh={ () => fetchRooms()?.promise } />
      <Table
        columns={ [
          { propertyId: 'name', captionKey: 'room' },
          { propertyId: 'actions', rowRenderer: RoomActionsRenderer }
        ] }
        keyPropertyId='gid'
        data={ rooms }
        noDataTextKey='no-rooms'
        loading={ loading }
      />
    </TableWrapper>
  );
};

export default Rooms;
