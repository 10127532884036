import * as React from 'react';
import scheduler, { OrganizationsResponse } from '../../services/scheduler';
import utils from '../utils';
import Dropdown from "../common/DropDown";
import { Organization } from '../../services/scheduler';
import { useTranslation } from 'react-i18next';

interface Props {
  allowNotSelected?: boolean;
  selectedOrganization?: string;
  onChange: (org: string | undefined) => void;
}

const OrganizationsDropDown: React.FC<Props> = ({ allowNotSelected, selectedOrganization, onChange }: Props) => {

  const { t } = useTranslation();
  const NOT_SELECTED = React.useMemo<Organization>(() => ({ gid: '', name: ' - ' + t('not-selected') + ' -' }), [t]);
  const [organizations, setOrganizations] = React.useState<OrganizationsResponse | undefined>();

  const organizationsWithNotSelected = React.useMemo<OrganizationsResponse | undefined>(() => {
    if (allowNotSelected) {
      const o = organizations?.slice();
      o?.unshift(NOT_SELECTED);
      return o;
    } else {
      return organizations;
    }
  }, [NOT_SELECTED, allowNotSelected, organizations]);

  const organizationOptions = React.useMemo(() =>
  organizationsWithNotSelected?.map(r => ({ key: r.gid, label: r.name, value: r.gid })
  ) || [], [organizationsWithNotSelected]);

  React.useEffect(() => {
    const cp = utils.makeCancelable(scheduler.organizations());
    cp.promise.then(res => setOrganizations(res.sort(utils.stringSorter<Organization>('name'))), () => console.log('chyba pri nacitani kontaktnich mist'));
    return cp.cancel;
  }, []);

  return (
    <Dropdown
      label={ t('contact-point') }
      required
      options={ organizationOptions }
      value={ selectedOrganization }
      placeholder={ t('select-contact-point') }
      onChange={ onChange }
    />
  );
};

export default OrganizationsDropDown;
