import * as React from 'react';
import { useNavigate } from 'react-router';
import { State } from '../../redux/reducers/index';
import { useSelector } from 'react-redux';
import SignalR from '../../SignalR';
import SessionListTable from './SessionListTable';
import Tabs from '../common/tabs/Tabs';
import Tab from '../common/tabs/Tab';
import Calendar from '../common/calendar/Calendar';
import qs from 'query-string';
import StatTable from '../common/stat-table/StatTable';
import { Claim } from '../../services/identity';
import Rooms from '../rooms/Rooms';
import LogTable from '../common/log-table/LogTable';

const SessionList: React.FC = () => {

  const navigate = useNavigate();
  const claims = useSelector<State, Array<Claim> | undefined>(state => state.general.sessionToken.location?.claims);

  const handleSelectSlot = React.useCallback((date: Date) => {
    const params = { start: date.getTime() };
    navigate('/location/sessions/create?' + qs.stringify(params));
  }, [navigate]);

  return (
    <>
      <SignalR />
      <Tabs>
        <Tab id='calendar' titleKey='calendar' hidden={ !claims?.includes('cal') }>
          <Calendar onCreate={ handleSelectSlot } />
        </Tab>
        <Tab id='overview' titleKey='overview' hidden={ !claims?.includes('ovr') }>
          <SessionListTable />
        </Tab>
        <Tab id='log' titleKey='log' hidden={ !claims?.includes('log') }>
          <LogTable />
        </Tab>
        <Tab id='stat' titleKey='statistics' hidden={ !claims?.includes('stats') }>
          <StatTable />
        </Tab>
        <Tab id='rooms' titleKey='rooms' hidden={ !claims?.includes('room') }>
          <Rooms />
        </Tab>
      </Tabs>
    </>
  );
};

export default SessionList;
