import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { State } from '../../../ClientApp/src/redux/reducers/index';
import { useSelector } from 'react-redux';
import Aad from './login/Aad';
import Call from './call/Call';
import Location from './location/Location';
import Organization from './organization/Organization';
import Room from './room/Room';
import Attendee from './attendee/Attendee';
import LocationSessionList from './location/SessionList';
import LocationCreateSession from './location/CreateSession';
import LocationDetail from './admin/LocationDetail';
import LocationLogin from './location/LocationLogin';
import OrganizationLogin from './organization/OrganizationLogin';
import OrganizationSessionList from './organization/SessionList';
import OrganizationCreateSession from './organization/CreateSession';
import RoomLogin from './room/RoomLogin';
import AttendeeLogin from './attendee/AttendeeLogin';

const Main: React.FC = () => {

  const locationToken = useSelector<State, string | undefined>(state => state.general.sessionToken.location?.token);
  const organizationToken = useSelector<State, string | undefined>(state => state.general.sessionToken.organization?.token);

  return (
    <React.Suspense fallback={ null }>
      <Routes>
        <Route path='/' element={ <Aad /> } />
        <Route path='location/*' element={ <Location /> }>
          { locationToken ?
            <>
              <Route path='sessions/create' element={ <LocationCreateSession /> } />
              <Route path='sessions/*' element={ <LocationSessionList /> } />
              <Route path='admin' element={ <LocationDetail /> } />
              <Route path='*' element={ <LocationLogin /> } />
            </>
            :
            <Route path='*' element={ <LocationLogin /> } />
          }
        </Route>
        <Route path='organization/*' element={ <Organization /> }>
          { organizationToken ?
            <>
              <Route path='sessions/create' element={ <OrganizationCreateSession /> } />
              <Route path='sessions/*' element={ <OrganizationSessionList /> } />
              <Route path='admin' element={ <LocationDetail /> } />
              <Route path='*' element={ <OrganizationLogin /> } />
            </>
            :
            <Route path='*' element={ <OrganizationLogin /> } />
          }
        </Route>
        <Route path='room/*' element={ <Room /> }>
          <Route path='*' element={ <RoomLogin /> } />
        </Route>
        <Route path='attendee/*' element={ <Attendee /> }>
          <Route path='*' element={ <AttendeeLogin /> } />
        </Route>
        <Route path='calling' element={ <Call /> } />
        <Route path='/*' element={ <Navigate to='/' /> } />
      </Routes>
    </React.Suspense>
  );
};

export default Main;
