import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logIcon from '../../assets/images/log.png';
import { useSelector } from 'react-redux';
import { State } from '../../redux/reducers/index';
import { Claim } from '../../services/identity';
import { Mode } from '../../redux/reducers/general';

interface Props {
  sessionGid: string;
}

const LinkToSessionLog: React.FC<Props> = ({ sessionGid }) => {

  const { t } = useTranslation();
  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);
  const claims = useSelector<State, Array<Claim> | undefined>(state => state.general.sessionToken[mode ?? 'location']?.claims);

  return (
    mode && claims?.includes('log') ?
      <Link className='link-icon' to={ '../log?sessionGid=' + sessionGid } title={ t('log') }>
        <img src={ logIcon } />
      </Link>
      : null
  )
}

export default LinkToSessionLog;
