import * as React from 'react';
import Logo from '../../assets/images/logo.png';
import { useSelector } from "react-redux";
import { State } from '../../redux/reducers/index';
import { GeneralState } from '../../redux/reducers/general';
import { Link } from 'react-router-dom';
import UserProfile from '../user-profile/UserProfile';
import UserAndAvatar from '../user-profile/UserAndAvatar';
import Countdown from './Countdown';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import utils from '../utils';

interface Props {
  title: string;
  homeUrl?: string;
  logoutUrl: string;
}

const Header: React.FC<Props> = ({ title, homeUrl, logoutUrl }: Props) => {

  const { mode, sessionToken } = useSelector<State, GeneralState>(state => state.general);
  const profile = sessionToken[mode ?? 'location']?.profile;
  const tokenValidity = mode ? sessionToken?.[mode]?.expiration : undefined;
  const [userProfileVisible, setUserProfileVisible] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const history = useNavigate();

  const handleLogout = React.useCallback(() => {
    history(logoutUrl);
  }, [history, logoutUrl]);

  const handleClickUser = React.useCallback(() => {
    setUserProfileVisible(v => !v);
  }, []);

  const handleClose = React.useCallback(() => {
    setUserProfileVisible(false);
  }, []);

  return (
    <div className='top-header'>
      <div className='header-grid'>
        <div className='header-grid-left'>
          <Link to='/' className='logo'>
            <img src={ Logo } alt='logo' />
          </Link>
          <div className='title'>
            { homeUrl ? <Link to={ homeUrl } className='title-link'>{ title }</Link> : <span>{ title }</span> }
          </div>
        </div>
        <div className='header-right'>
          { mode === 'location' && tokenValidity ?
            <div className='user-header-logout'>
              <span>{ t('auto-logout') }</span>
              <span className='expiration-link' title={ tokenValidity ? utils.formatDateTime(tokenValidity) : undefined }>
                <Countdown zero={ new Date(tokenValidity) } onZero={ handleLogout } />
              </span>
            </div>
            : null
          }
          { tokenValidity && profile ?
            <div className='header-user'>
              {/* <button onClick={ handleClickUser }>{ general.displayName }</button> */}
              <UserAndAvatar profile={ profile } onClick={ handleClickUser } />
              { userProfileVisible ? <UserProfile profile={ profile } logoutUrl={ logoutUrl } onClose={ handleClose } /> : null }
            </div>
            : null
          }
        </div>
      </div>
    </div>
  );
};

export default Header;
