import { Props as TabProps } from './Tab';

import * as React from 'react';
import TabHeader from './TabHeader';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultTab?: string;
  hideHeadersIfOnlyOne?: boolean;
  children: React.ReactElement<TabProps>[] | React.ReactElement<TabProps>;
  onChange?: (tab: string) => void;
}

const Tabs: React.FC<Props> = ({ defaultTab, hideHeadersIfOnlyOne, children, onChange }: Props) => {

  const allTabs = React.Children.toArray(children);
  const tabs = allTabs.filter(ch => React.isValidElement<{hidden: boolean}>(ch) && !ch.props.hidden);
  const defTab = React.useMemo(() => defaultTab ?? (React.isValidElement<{id: string}>(tabs[0]) ? tabs[0].props.id : null), [defaultTab, tabs]);
  const { t } = useTranslation();

  const handleClick = React.useCallback((i) => {
    const ti = tabs[i];
    if (onChange && React.isValidElement<{ id: string }>(ti)) {
      onChange(ti.props.id);
    }
  }, [onChange, tabs]);

  return (
    <>
      {tabs.length === 1 ? (
        hideHeadersIfOnlyOne ? null : (
          <h3 className="c-heading--sm">{ React.isValidElement<{ title: string }>(tabs[0]) && tabs[0].props.title }</h3>
        )
      ) : (
        <div className='tab'>
          { tabs.map((ch, i) => (
            React.isValidElement<{ title?: string, titleKey?: string, id: string }>(ch) ?
              <TabHeader key={ i } id={ ch.props.id } onClick={ handleClick.bind(null, i) }>
                { ch.props ? (ch.props.titleKey ? t(ch.props.titleKey) : ch.props.title) : null }
              </TabHeader>
              : null
          ))}
        </div>
      )}
      <Routes>
        { tabs.map(tab => (React.isValidElement<{ id: string }>(tab) ? <Route key={ tab.props.id } path={ tab.props.id } element={ tab } /> : null)) }
        { defTab ? <Route index element={ <Navigate to={ defTab } /> } /> : null }
      </Routes>
    </>
  );
};

export default Tabs;
