import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Page from '../common/Page';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../redux/reducers/index';
import { setMode } from '../../redux/actions/general';
import { useTranslation } from 'react-i18next';

const Organization: React.FC = () => {

  const dispatch = useDispatch();
  const organization = useSelector<State, string | undefined>(state => state.general.organization);
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(setMode('organization'));
  }, [dispatch]);

  return (
    <Page title={ organization || t('contact-point') } homeUrl='/organization/sessions'>
      <Outlet />
    </Page>
  );
};

export default Organization;
