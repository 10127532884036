import * as React from "react";
import "../../assets/css/spinner.css";

interface Props {
    width? : string | number;
    height? : string | number;
    topMargin? : number;
    bottomMargin? : number;
    color? : string;
    className? : string;
}

const Spinner: React.FC<Props> = ({ width, height = 11, topMargin, bottomMargin, color, className }: Props) => {

    if (!width) {
        if (typeof(height) === "number") {
            width = height * 3 + 30;
        } else {
            width = "100%";
        }
    }

    return (
        <div style={{ width, height, marginTop : topMargin, marginBottom : bottomMargin }} className={ className ? className + " c-spinner" : "c-spinner" }>
            <div className="s1" style={{ backgroundColor : color }} />
            <div className="s2" style={{ backgroundColor : color }} />
            <div className="s3" style={{ backgroundColor : color }} />
        </div>
    );
}

export default Spinner;
