import * as React from 'react';
import '../../../assets/css/calendar.css';
import Day from './Day';
import OrganizationsDropDown from '../OrganizationsDropDown';
import utils from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../../redux/reducers/index';
import { setOrganizationGid } from '../../../redux/actions/general';
import scheduler, { SessionsResponse, DaysOffResponse, Room } from '../../../services/scheduler';
import { Mode } from '../../../redux/reducers/general';
import DayHeaders from './DayHeaders';
import CalendarHeader from './CalendarHeader';

type SessionCache = {
  [dateOfMonday: string]: SessionsResponse;
}

export type WorkingHourInterval = {
  start: number;
  end: number;
}

export type WorkingHours = {
  [day: number]: WorkingHourInterval[];
}

interface Props {
  onCreate?: (date: Date) => void;
}

const hourMilis = 60 * 60 * 1000;
const dayMilis = 24 * hourMilis;
const days = Array(7).fill(0).map((x,i)=>i);

const getFirstDay = () => {
  return -utils.dayOfWeek();
}

const toDate = (ms: number) => {
  const d = new Date(ms);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const res = [d.getFullYear(), month < 10 ? '0' + month : month, day < 10 ? '0' + day : day].join('-');
  return res;
}

const Calendar: React.FC<Props> = ({ onCreate }: Props) => {

  const now = Date.now();
  const [firstDay, setFirstDay] = React.useState(getFirstDay());
  const monday = React.useMemo(() => toDate(Date.now() + (dayMilis * firstDay)), [firstDay]);
  const selectedOrganization = useSelector<State, string | undefined>(state => state.general.organizationGid);
  const locOrgGid = useSelector<State, string | undefined>(state => state.general.locOrgGid);
  const dispatch = useDispatch();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const rooms = useSelector<State, Array<Room> | undefined>(state => state.general.rooms);
  const [organizationRooms, setOrganizationRooms] = React.useState<Array<Room> | undefined>();
  const [locationSessions, setLocationSessions] = React.useState<SessionCache>({});
  const [organizationSessions, setOrganizationSessions] = React.useState<SessionCache>({});
  const [locationWorkingHours, setLocationWorkingHours] = React.useState<WorkingHours | undefined>();
  const [organizationWorkingHours, setOrganizationWorkingHours] = React.useState<WorkingHours | undefined>();
  const [daysOff, setDaysOff] = React.useState<DaysOffResponse | undefined>();
  const workingHours = React.useMemo(() => {
    const res = [];
    if (locationWorkingHours) res.push(locationWorkingHours);
    if (organizationWorkingHours) res.push(organizationWorkingHours);
    return res.length ? res : undefined;
  }, [locationWorkingHours, organizationWorkingHours]);
  const sessionListUpdated = useSelector<State, number | undefined>(state => state.general.sessionListUpdated);
  const organization = useSelector<State, string | undefined>(state => state.general.organizationGid);
  const [loadingLoc, setLoadingLoc] = React.useState(false);
  const [loadingOrg, setLoadingOrg] = React.useState(false);
  const loading = React.useMemo(() => loadingLoc || loadingOrg, [loadingLoc, loadingOrg]);
  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);

  React.useEffect(() => {
    setOrganizationSessions({});
  }, [locOrgGid]);

  const fetchLocationSessions = React.useCallback((clearCache?: boolean) => {
    if (!locationSessions[monday] || clearCache) {
      const cp = utils.makeCancelable(scheduler.plannedSessions('location', monday));
      cp.promise.then(
        res => {
          setLocationSessions(clearCache ? { [monday]: res } : { ...locationSessions, [monday]: res });
        },
        () => {
          setLocationSessions(clearCache ? { [monday]: [] } : { ...locationSessions, [monday]: [] });
          console.log('chyba pri nacitani udalosti');
        }
      );
      return cp;
    }
  }, [locationSessions, monday]);

  const fetchOrganizationSessions = React.useCallback((clearCache?: boolean) => {
    if ((!organizationSessions[monday] || clearCache) && mode) {
      const cp = utils.makeCancelable(scheduler.plannedSessions(mode, monday, mode === 'location' ? organization : undefined));
      cp.promise.then(
        res => {
          setOrganizationSessions(clearCache ? { [monday]: res } : { ...organizationSessions, [monday]: res });
        },
        () => {
          setOrganizationSessions(clearCache ? { [monday]: [] } : { ...organizationSessions, [monday]: [] });
          console.log('chyba pri nacitani udalosti');
        }
      );
      return cp;
    }
  }, [organizationSessions, monday, mode, organization]);

  React.useEffect(() => {
    if (mode === 'location') {
      const fls = fetchLocationSessions();
      if (fls) {
        setLoadingLoc(true);
        fls.promise.finally(() => setLoadingLoc(false));
        return () => {
          fls.cancel();
          setLoadingLoc(false);
        }
      }
    }
  }, [fetchLocationSessions, sessionListUpdated, mode, locOrgGid]);

  React.useEffect(() => {
    const fos = fetchOrganizationSessions();
    if (fos) {
      setLoadingOrg(true);
      fos.promise.finally(() => setLoadingOrg(false));
      return () => {
        fos.cancel();
        setLoadingOrg(false);
      }
    }
  }, [fetchOrganizationSessions, locOrgGid]);

  React.useEffect(() => {
    if (mode === 'location') {
      const cp = utils.makeCancelable(scheduler.locationWorkingHours(mode || 'location'));
      cp.promise.then(
        setLocationWorkingHours,
        () => {
          setLocationWorkingHours([]);
          console.log('chyba pri nacitani pracovni doby OJ');
        }
      );
      return cp.cancel;
    }
  }, [mode, locOrgGid]);

  React.useEffect(() => {
    const cp = utils.makeCancelable(scheduler.daysOff(mode || 'location'));
    cp.promise.then(
      setDaysOff,
      () => {
        setDaysOff([]);
        console.log('chyba pri nacitani dnu volna');
      }
    );
    return cp.cancel;
  }, [mode, locOrgGid]);

  React.useEffect(() => {
    if (organization || mode === 'organization') {
      const cp = utils.makeCancelable(scheduler.locationWorkingHours(mode || 'location', mode === 'location' ? organization : undefined));
      cp.promise.then(
        setOrganizationWorkingHours,
        () => {
          setOrganizationWorkingHours([]);
          console.log('chyba pri nacitani pracovni doby kontaktniho mista');
        }
      );
      return cp.cancel;
    } else {
      setOrganizationWorkingHours(undefined);
    }
  }, [mode, organization, locOrgGid]);

  React.useEffect(() => {
    if (organization || mode === 'organization') {
      const cp = utils.makeCancelable(scheduler.rooms(mode || 'location', mode === 'location' ? organization : undefined));
      cp.promise.then(
        setOrganizationRooms,
        () => { setOrganizationRooms([]); console.log('chyba pri nacitani mistnosti kontaktniho mista'); }
      );
      return cp.cancel;
    } else {
      setOrganizationRooms([]);
    }
  }, [organization, mode, locOrgGid]);

  const handleRefresh = React.useCallback(() => {
    if (mode === 'location') {
      const p1 = fetchLocationSessions(true)?.promise;
      const p2 = fetchOrganizationSessions(true)?.promise;
      return Promise.all([p1, p2]);
    } else {
      return fetchOrganizationSessions(true)?.promise;
    }
  }, [fetchLocationSessions, fetchOrganizationSessions, mode]);

  React.useEffect(() => {
    if (workingHours?.length) {
      const whElements = Array.from(document.querySelectorAll('div.half-hour:not(.disabled)'));
      if (whElements?.length) {
        const minScroll = whElements.map(el => el.getBoundingClientRect().top).reduce((acc, item) => Math.min(acc, item), 10000);
        const containerEl = containerRef?.current;
        if (containerEl && minScroll < 10000) {
          const containerTop = containerEl.getBoundingClientRect().top;
          const containerScrollTop = containerEl.scrollTop;
          containerEl.scroll({ top: minScroll - containerTop + containerScrollTop - 70 });
          return;
        }
      }
    }
    containerRef.current?.scroll({ top: 450 });
  }, [workingHours])

  const handleMinusOneWeek = React.useCallback(() => {
    setFirstDay(d => d - 7);
  }, []);

  const handlePlusOneWeek = React.useCallback(() => {
    setFirstDay(d => d + 7);
  }, []);

  const handleToday = React.useCallback(() => {
    setFirstDay(getFirstDay());
  }, []);

  const handleChangeOrganization = React.useCallback((org: string | undefined) => {
    dispatch(setOrganizationGid(org));
  }, [dispatch]);

  return (
    <div className='calendar'>
      <CalendarHeader refreshing={ loading } onBack={ handleMinusOneWeek } onToday={ handleToday } onForward={ handlePlusOneWeek } onRefresh={ handleRefresh }>
        { mode === 'location' ?
          <div className='left-part'>
            <OrganizationsDropDown allowNotSelected selectedOrganization={ selectedOrganization } onChange={ handleChangeOrganization } />
          </div>
          : null
        }
      </CalendarHeader>
      <div ref={ containerRef } className='scrollable'>
        <DayHeaders firstDay={ firstDay } />
        <div className='body'>
          { days.map(day =>
            <Day
              key={ firstDay + day }
              day={ dayMilis * (firstDay + day) + now }
              workingHours={ daysOff?.some(d => d === toDate(dayMilis * (firstDay + day) + now)) ? undefined : workingHours }
              locationSessions={ locationSessions[monday] }
              organizationSessions={ organizationSessions[monday] }
              tooltipDirection={ day < 3 ? 'right' : 'left' }
              numberOfLocationRooms={ rooms?.length || 0 }
              numberOfOrganizationRooms={ organizationRooms?.length || 0 }
              onCreate={ onCreate }
            />
          ) }
        </div>
      </div>
    </div>
  );
};

export default Calendar;
