import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import LanguageSelection from '../common/LanguageSelection';
import LocationList from './LocationList';
import './userprofile.css';
import UserAndAvatar from './UserAndAvatar';
import { Profile } from '../../services/identity';
import Version from './Version';

interface Props {
  profile: Profile;
  logoutUrl?: string;
  onClose: () => void;
}

const UserProfile: React.FC<Props> = ({ profile, logoutUrl, onClose }: Props) => {

  const { t } = useTranslation();
  const history = useNavigate();
  const divRef = React.useRef<HTMLDivElement>(null);

  const handleLogout = React.useCallback(() => {
    if (logoutUrl) {
      history(logoutUrl);
    }
  }, [history, logoutUrl]);

  const handleDocumentClick = React.useCallback(e => {
    if (!divRef?.current?.contains(e.target)) {
      onClose();
    }
  }, [onClose]);

  React.useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => document.removeEventListener('click', handleDocumentClick);
  }, [handleDocumentClick]);

  return (
    <div ref={ divRef } className='user-profile'>
      <div className='user-profile-lang'>
        <LanguageSelection />
        <Version />
        { logoutUrl ? <Link to={ logoutUrl } onClick={ handleLogout } className='logout'>{ t('logout') }</Link> : null }
      </div>
      <UserAndAvatar profile={ profile } />
      <LocationList onClose={ onClose } />
    </div>
  );
};

export default UserProfile;
