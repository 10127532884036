import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

const TableWrapper: React.FC<Props> = ({ children }) => {

  return (
    <div className='grid-with-controls'>
      { children }
    </div>
  )
}

export default TableWrapper;
