import * as React from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

interface Props {
  numberOfPages: number;
}

const Paging: React.FC<Props> = ({ numberOfPages }) => {

  const pages = React.useMemo(() => Array.from({ length: numberOfPages }, (v, i) => i + 1), [numberOfPages]);
  const location = useLocation();
  const params = React.useMemo(() => new URLSearchParams(location.search), [location.search]);
  const currentPage = React.useMemo(() => {
    const p = params.get('page');
    return p ? Number.parseInt(p) : 1;
  }, [params]) ?? 1;

  return (
    <>
    { pages.map(page => {
      const p = new URLSearchParams(params);
      p.set('page', '' + page);
      return currentPage === page ?
        <span key={ page } className='page'>{ page }</span>
        :
        <Link key={ page } className='page' to={ location.pathname + '?' + p }>{ page }</Link>;
    }) }
    </>
  )
}

export default Paging;
