import * as React from 'react';

interface Props {
  align?: 'right' | 'left';
  className?: string;
  children?: React.ReactNode;
}

const TableCell: React.FC<Props> = ({ align, className, children }: Props) => {

  return (
    <td className={ className ? ((align ? (align + ' ') : '') + className) : align }>
      { children }
    </td>
  )
}

export default TableCell;
