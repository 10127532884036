import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State } from '../../redux/reducers/index';
import { Mode } from '../../redux/reducers/general';
import { Group, Profile } from '../../services/identity';
import { ReactComponent as Gear } from '../../assets/images/gear.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { hpUrls } from '../utils';
import identity from '../../services/identity';
import { setGroup, setSessionToken, setMode, setLocation, setOrganization, setLocOrgGid } from '../../redux/actions/general';
import { useNavigate } from 'react-router';
import Button from '../common/Button';

type Props = {
  group: Group;
  selected: boolean;
  onClose: () => void;
}

const LocationItem: React.FC<Props> = ({ group, selected, onClose }: Props) => {

  const { t } = useTranslation();
  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);
  const profile = useSelector<State, Profile | undefined>(state => state.general.sessionToken?.[mode ?? 'location']?.profile);
  const dispatch = useDispatch();
  const history = useNavigate();
  const [switching, setSwitching] = React.useState<boolean>(false);

  const handleSwitch = React.useCallback(() => {
    setSwitching(true);
    if (group && mode) {
      const newMode = group.type === "OJ" ? 'location' : 'organization';
      identity.switchLocation(mode, group.id).then(
        res => {
          dispatch(setGroup(group));
          dispatch(setSessionToken(newMode, res, profile));
          if (newMode !== mode) {
            dispatch(setSessionToken(mode));
          }
          dispatch(setLocOrgGid(group.id));
          dispatch(setMode(newMode));
          dispatch(group.type === "OJ" ? setLocation(group.name) : setOrganization(group.name));
          // setSwitching(false);
          history(hpUrls[newMode]);
          onClose();
        },
        err => {
          setSwitching(false);
          console.log('chyba pri prepinani lokace', err);
          onClose();
        }
      )
    }
  }, [dispatch, group, history, mode, onClose, profile]);

  return (
    <div className={ 'location-item' + (selected ? ' selected' : '') }>
      <span>{ group.name }</span>
      { selected ?
        <Link to={ '/' + mode + '/admin' } className='admin'>
          <Gear onClick={ onClose } />
        </Link>
        :
        <Button loading={ switching } onClick={ handleSwitch }>{ t('switch') }</Button>
      }
    </div>
  );
};

export default LocationItem;
