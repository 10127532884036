import * as React from 'react';
import { useNavigate } from 'react-router';
// import { useLocation } from 'react-router-dom';
import scheduler from '../../services/scheduler';
// import { twoDigits } from '../common/Countdown';
import Form from '../common/Form';
import InputBox from '../common/InputBox';
import Dropdown from "../common/DropDown";
import Button from '../common/Button';
import Heading from '../common/Heading';
import ButtonRow from '../common/ButtonRow';
// import Tabs from '../common/tabs/Tabs';
// import Tab from '../common/tabs/Tab';
import OrganizationsDropDown from '../common/OrganizationsDropDown';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../redux/reducers/index';
import { setOrganizationGid } from '../../redux/actions/general';
import { Room } from '../../services/scheduler';
import utils from '../utils';
import { useTranslation } from 'react-i18next';
import DateTimeInputBox from '../common/DateTimeInputBox';

const CreateSession: React.FC = () => {

  const rs = useSelector<State, Array<Room> | undefined>(state => state.general.rooms);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<Date>(new Date());
  const [startDateValid, setStartDateValid] = React.useState<boolean>(true);
  const navigate = useNavigate();
  // const location = useLocation();
  const [duration, setDuration] = React.useState<number>(location.hostname === 'localhost' ? 60 : 20);
  const [room, setRoom] = React.useState<string>('');
  const [prisonerName, setPrisonerName] = React.useState<string>('');
  const [attendeeName, setAttendeeName] = React.useState<string>('');
  const [attendeeEmail, setAttendeeEmail] = React.useState<string>('');
  const [attendeePin, setAttendeePin] = React.useState<string>('');
  const [organizationMode, /*setOrganizationMode*/] = React.useState<boolean>(true);
  const organization = useSelector<State, string | undefined>(state => state.general.organizationGid);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    const d = new Date();
    d.setMinutes(0);
    d.setMilliseconds(0);
    setStartDate(new Date(d.getTime() + 3600000));
  }, []);

  const rooms = React.useMemo(() =>
        rs?.filter(r => r.isActive)
        .map(r => ({ key: r.gid, label: r.name, value: r.code }))
        .sort(utils.stringSorter('label')) || []
  , [rs]);

  React.useEffect(() => {
    setRoom(r => {
      if (rooms?.length && (!r || !rooms.some(ro => ro.value === r))) {
        return rooms[0].value;
      } else {
        return r;
      }
    });
  }, [rooms]);

  const handleChangeOrganization = React.useCallback((org) => dispatch(setOrganizationGid(org)), [dispatch]);

  React.useEffect(() => {
    if (organizationMode) {
      setAttendeeEmail('');
      setAttendeePin('');
      const s = qs.parse(location.search);
      if (typeof s.organization === 'string' && s.organization) {
        handleChangeOrganization(s.organization);
      }
      if (typeof s.start === 'string' && s.start) {
        const d = new Date(Number.parseInt(s.start));
        if (d) {
          setStartDate(d);
        }
      }
    } else {
      handleChangeOrganization('');
    }
  }, [organizationMode, handleChangeOrganization]);

  const handleChangeDuration = React.useCallback((value) => setDuration(Number(value)), []);

  const handleCreate = React.useCallback(() => {
    if (startDate && startDateValid && duration && room && prisonerName && attendeeName && (organizationMode ? organization : (attendeeEmail && attendeePin))) {
      setLoading(true);
      scheduler.session(prisonerName, startDate, duration, attendeeName, attendeeEmail || undefined, attendeePin || undefined, organization || undefined, room).then(
        () => {
          setLoading(false);
          navigate('/location/sessions');
        },
        () => {
          setLoading(false);
          console.log('chyba pri vytvareni udalosti');
        }
      );
    }
  }, [startDate, startDateValid, duration, room, prisonerName, attendeeName, organizationMode, organization, attendeeEmail, attendeePin, navigate]);

  const handleBack = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleChangeRoom = React.useCallback(r => {
    setRoom(r || '');
  }, []);

  return (
    <Form onSubmit={ handleCreate }>
      <Heading>{ t('new-session') }</Heading>
      {/* <InputBox label={ t('start-date') } type='date' required placeholder='yyyy-mm-dd' error={ startDateValid ? (isInThePast ? t('start-in-the-past') : null) : t('invalid-date-format') } value={ startDateString } onChange={ handleChangeStartDate } onBlur={ handleBlurStartDate } />
      <InputBox label={ t('start-time') } type='time' step={ 1800 } required error={ isInThePast ? t('start-in-the-past') : null } value={ startTime } onChange={ setStartTime } /> */}

      <DateTimeInputBox label={ t('start-time') } required value={ startDate } onChange={ setStartDate } onChangeValid={ setStartDateValid } />

      <InputBox label={ t('duration') } type='number' required disabled value={ String(duration) } onChange={ handleChangeDuration } />
      <Dropdown label={ t('room') } required options={ rooms } value={ room } placeholder={ t('select-room') } onChange={ handleChangeRoom } />
      <Heading>{ t('prisoner') }</Heading>
      <InputBox label={ t('name') } required value={ prisonerName } onChange={ setPrisonerName } />
      <Heading>{ t('attendee') }</Heading>
      {/* <Tabs activeTab={ organizationMode ? 'org' : 'loc' } onChange={ handleChangeOrganizationMode }>
        <Tab id='org' title='Kontaktní místo'> */}
          <InputBox label={ t('name') } required value={ attendeeName } onChange={ setAttendeeName } />
          <OrganizationsDropDown selectedOrganization={ organization || undefined } onChange={ handleChangeOrganization } />
        {/* </Tab>
        <Tab id='loc' title='Externí'>
          <InputBox label='Jméno' required value={ attendeeName } onChange={ setAttendeeName } />
          <InputBox label='Email' required value={ attendeeEmail } onChange={ setAttendeeEmail } />
          <InputBox label='PIN' required value={ attendeePin } onChange={ setAttendeePin } />
        </Tab>
      </Tabs> */}
      <ButtonRow>
        <Button loading={ loading } disabled={ !startDate || !startDateValid || !duration || !room || !prisonerName || !attendeeName || (organizationMode ? !organization : (!attendeeEmail || !attendeePin)) || loading }>{ t('create') }</Button>
        <Button onClick={ handleBack }>{ t('back') }</Button>
      </ButtonRow>
    </Form>
  );
};

export default CreateSession;
