import * as React from 'react';
import { SessionStatus } from '../../../services/scheduler';

interface Props {
  status?: SessionStatus;
  children?: React.ReactNode;
}

const TableRow: React.FC<Props> = ({ status, children }: Props) => {

  const rowClassName = React.useCallback((s: SessionStatus | undefined) => {
    switch (s) {
      case 'REJECTED': return 'red';
      default: return undefined;
    }
  }, []);

  return (
    <tr className={ rowClassName(status) }>
      { children }
    </tr>
  )
}

export default TableRow;
