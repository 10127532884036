import * as React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { setSessionToken, setLocation, setLocOrgGid, setGroups } from '../../redux/actions/general';
import identity from '../../services/identity';
import qs from 'query-string';
import Form from '../common/Form';
import InputBox from '../common/InputBox';
import Button from '../common/Button';
import ButtonRow from '../common/ButtonRow';
import { useTranslation } from 'react-i18next';

const LocationLogin: React.FC = () => {

  const [loc, setLoc] = React.useState<string>('');
  const [passCode, setPassCode] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const history = useNavigate();
  const browserLocation = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(setSessionToken('location'));
    dispatch(setLocation());
    const rId = qs.parse(browserLocation.search).id;
    if (rId) {
      const r = Array.isArray(rId) ? rId[0] : rId;
      if (r) {
        setLoc(r);
      }
    }
  }, [browserLocation.search, dispatch]);

  const reset = React.useCallback(() => {
    setError(true);
    setLoading(false);
    dispatch(setSessionToken('location'));
  }, [dispatch]);

  const handleClick = React.useCallback(() => {
    if (loc && passCode) {
      setLoading(true);
      setError(false);
      identity.connectLocation(loc, passCode).then(
        res => {
          if (res) {
            setLoading(false);
            dispatch(setSessionToken('location', res));
            dispatch(setLocation(res.name || loc));
            dispatch(setGroups(res.groups ?? [], res.groups?.[0]));
            dispatch(setLocOrgGid(res.groups?.[0]?.id));
            history('/location/sessions');
          } else {
            reset();
          }
        },
        reset
      );
    }
  }, [loc, passCode, dispatch, history, reset]);

  return (
    <Form type='small' onSubmit={ handleClick }>
      <InputBox label={ t('ou') } id='locationId' autoComplete='username' required value={ loc } onChange={ setLoc } />
      <InputBox label={ t('password') } type='password' id='locationPassCode' autoComplete='current-password' required value={ passCode } onChange={ setPassCode } />
      <ButtonRow>
        <Button loading={ loading } disabled={ !loc || !passCode || loading } error={ error ? t('login-error')
         : undefined }>{ t('login') }</Button>
      </ButtonRow>
    </Form>
  );
};

export default LocationLogin;
