import * as React from 'react';
import RefreshButton from './RefreshButton';

interface Props {
  refreshing?: boolean;
  left?: React.ReactNode;
  right?: React.ReactNode;
  children?: React.ReactNode;
  onRefresh?: () => Promise<unknown> | void;
}

const HeaderControls: React.FC<Props> = ({ refreshing, left, right, children, onRefresh }: Props) => {

  return (
      <div className='controls'>
        <div className='left-part'>
          { left }
        </div>
        <div className='middle-part'>
          { children }
        </div>
        <div className='right-part'>
          { right }
          { onRefresh ? <RefreshButton refreshing={ refreshing } onClick={ onRefresh } /> : null }
        </div>
      </div>
  );
};

export default HeaderControls;
