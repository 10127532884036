import * as React from 'react';
import scheduler from '../../services/scheduler';
import Form from '../common/Form';
import InputBox from '../common/InputBox';
import Button from '../common/Button';
import ButtonRow from '../common/ButtonRow';
import CheckBox from '../common/CheckBox';
import Heading from '../common/Heading';
import WorkingHoursDay from './WorkingHoursDay';
import utils from '../utils';
import { State } from '../../redux/reducers/index';
import { GeneralState } from '../../redux/reducers/general';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setOrganization, setLocation, setGroups } from '../../redux/actions/general';
import Spinner from '../common/Spinner';
import { useNavigate } from 'react-router-dom';

type WH = {
  from?: string,
  to?: string
}

type WHS = Array<Array<WH>>

type DayOfWeek = {
  key: string,
  pos: number
}

const daysOfWeek: Array<DayOfWeek> = [{ key: 'mon', pos: 1 }, { key: 'tue', pos: 2 }, { key: 'wed', pos: 3 }, { key: 'thu', pos: 4 }, { key: 'fri', pos: 5 }, { key: 'sat', pos: 6 }, { key: 'sun', pos: 0 }];

const LocationDetail: React.FC = () => {

  const { mode, locOrgGid, location, organization, group, groups, sessionToken } = useSelector<State, GeneralState>(state => state.general);
  const isAdmin = React.useMemo(() => mode ? sessionToken?.[mode]?.claims?.includes('admin') : false, [mode, sessionToken]);
  const [name, setName] = React.useState<string>((mode === 'location' ? location : organization) ?? '');
  const [active, setActive] = React.useState<boolean>(true);
  const [authority, setAuthority] = React.useState<string>('');
  const [workingHours, setWorkingHours] = React.useState<WHS | undefined>();
  const daysValid = React.useRef<Array<boolean>>([]);
  const [isValid, setValid] = React.useState<boolean>(true);
  const [email, setEmail] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (mode && locOrgGid) {
      const cp = utils.makeCancelable(scheduler.getLocation(mode, locOrgGid));
      cp.promise.then(
        res => {
          setName(res.name);
          setActive(res.isActive);
          setAuthority(res.authority);
          setEmail(res.email);
          const wh = [[{}], [{}], [{}], [{}], [{}], [{}], [{}]];
          Object.keys(res.workingHours).forEach(d => {
            const day = Number.parseInt(d);
            const fromTo = res.workingHours[day];
            wh[day] = fromTo?.map(ft => {
              const f = ft?.split('-')
              return { from: f[0], to: f[1] };
            });
          });
          setWorkingHours(wh);
        },
        () => console.log('chyba pri nacitani OJ')
      );
      return cp.cancel;
    }
  }, [mode, locOrgGid]);

  const handleSave = React.useCallback(() => {
    if (name && mode && locOrgGid) {
      setLoading(true);
      setError(false);
      const wh: { [day: number]: Array<string> } = {};
      workingHours?.forEach((d, i) => {
        d.forEach((di, ii) => {
          if (di?.from && di?.to) {
            if (!wh[i]) {
              wh[i] = [];
            }
            wh[i][ii] = di.from + '-' + di.to;
          }
        });
      });
      scheduler.updateLocation(mode, locOrgGid, name, authority, active, email || undefined, wh).then(
        () => {
          setLoading(false);
          dispatch(mode === 'location' ? setLocation(name) : setOrganization(name));
          if (group) {
            const gr = { ...group, name };
            dispatch(setGroups(groups?.map(g => g.id === gr.id ? gr : g) ?? [], gr));
          }
        },
        () => {
          setLoading(false);
          setError(true);
        }
      );
    }
  }, [active, authority, dispatch, email, group, groups, mode, name, locOrgGid, workingHours]);

  const handleChangeWorkingHours = React.useCallback((day: number, rank: number, wh: WH) => {
    setWorkingHours(w => {
      const res = w?.slice() ?? [];
      if (!res[day]) {
        res[day] = [];
      }
      res[day][rank] = wh;
      return res;
    })
  }, []);

  const handleAdd = React.useCallback((day: number, rank: number) => {
    setWorkingHours(wh => {
      const res = wh?.slice() ?? [];
      const d = res[day]?.slice() ?? [];
      d.splice(rank + 1, 0, {});
      res[day] = d;
      return res;
    });
  }, []);

  const handleRemove = React.useCallback((day: number, rank: number) => {
    setWorkingHours(wh => {
      const res = wh?.slice() ?? [];
      const d = res[day]?.slice();
      if (d.length > 1) {
        d.splice(rank, 1);
      } else {
        d[rank] = { from: '', to: '' }
      }
      res[day] = d;
      return res;
    });
  }, []);

  const handleBack = React.useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleChangeValid = React.useCallback((day: number, valid: boolean) => {
    daysValid.current[day] = valid;
    setValid(!daysValid.current.some(v => v === false));
  }, []);

  return (
    <>
    <Form type='small' onSubmit={ handleSave }>
      <InputBox label={ t('location-name') } id='name' autoComplete='username' required value={ name || '' } onChange={ setName } />
      <CheckBox label={ t('active') } id='active' checked={ active } disabled={ !isAdmin } onChange={ setActive } />
      <InputBox label={ t('authority') } id='authority' required value={ authority || '' } disabled={ !isAdmin } onChange={ setAuthority } />
    </Form>
    <Form onSubmit={ handleSave }>
      <Heading>{ t('working-hours') }</Heading>
      <div className='wh'>
        { workingHours ?
          daysOfWeek.map(({ key: d, pos: i }) => {
            return workingHours[i].map((wh, ii) =>
              <WorkingHoursDay
                key={ '' + i + ii }
                day={ i }
                rank={ ii }
                label={ ii == 0 ? t(d) : undefined }
                from={ wh.from }
                to={ wh.to }
                onChange={ handleChangeWorkingHours }
                onAdd={ handleAdd }
                onRemove={ handleRemove }
                onChangeValid={ handleChangeValid.bind(null, (i * 10) + ii) }
              />
            )}
          )
          :
          <div className='spinner-center'><Spinner /></div>
        }
      </div>
      { mode === 'organization' ?
        <InputBox label={ t('email') } id='email' required value={ email || '' } onChange={ setEmail } />
        : null
      }
      <ButtonRow>
        <Button loading={ loading } disabled={ !name || !isValid } error={ error ? t('save-error') : undefined }>{ t('save') }</Button>
        <Button onClick={ handleBack }>{ t('back') }</Button>
      </ButtonRow>
    </Form>
    </>
  );
};

export default LocationDetail;
