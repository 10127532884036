import * as React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { setSessionToken, setRoom as setRoomRedux, setLocation as setLocationRedux } from '../../redux/actions/general';
import identity from '../../services/identity';
import qs from 'query-string';
import Form from '../common/Form';
import InputBox from '../common/InputBox';
import Button from '../common/Button';
import ButtonRow from '../common/ButtonRow';
import { useTranslation } from 'react-i18next';

const RoomLogin: React.FC = () => {

  const [location, setLocation] = React.useState<string>('');
  const [room, setRoom] = React.useState<string>('');
  const [passCode, setPassCode] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const history = useNavigate();
  const loc = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(setRoomRedux());
    dispatch(setSessionToken('room'));
    const rId = qs.parse(loc.search).id;
    if (rId) {
      const r = Array.isArray(rId) ? rId[0] : rId;
      if (r) {
        const lr = r.split('/');
        setLocation(lr[0] ?? '');
        setRoom(lr[1] ?? '');
      }
    }
  }, [loc.search, dispatch]);

  const reset = React.useCallback(() => {
    setError(true);
    setLoading(false);
    dispatch(setSessionToken('room'));
  }, [dispatch]);

  const handleClick = React.useCallback(() => {
    if (location && room && passCode) {
      setError(false);
      setLoading(true);
      identity.connectRoom(location + '/' + room, passCode).then(
        res => {
          if (res) {
            setLoading(false);
            dispatch(setSessionToken('room', res));
            dispatch(setLocationRedux(location));
            dispatch(setRoomRedux(res.name || room));
            history('/calling');
          } else {
            reset();
          }
        },
        reset
      );
    }
  }, [location, room, passCode, dispatch, history, reset]);

  return (
    <Form type='small' onSubmit={ handleClick }>
      <InputBox label={ t('ou-cp') } id='locationId' autoComplete='username' required value={ location } onChange={ setLocation } />
      <InputBox label={ t('room') } id='roomId' autoComplete='username' required value={ room } onChange={ setRoom } />
      <InputBox label={ t('password') } type='password' id='roomPassCode' autoComplete='current-password' required value={ passCode } onChange={ setPassCode } />
      <ButtonRow>
        <Button loading={ loading } disabled={ !location || !room || !passCode || loading } error={ error ? t('login-error') : undefined }>{ t('login') }</Button>
      </ButtonRow>
    </Form>
  );
};

export default RoomLogin;
