import * as React from 'react';
import { SessionsResponse } from '../../../services/scheduler';
import { TooltipDirection } from './Day';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/reducers/index';
import { Mode } from '../../../redux/reducers/general';
import { useTranslation } from 'react-i18next';

interface Props {
  sessions: SessionsResponse | undefined;
  tooltipDirection: TooltipDirection;
}

const TooltipContent: React.FC<Props> = ({ sessions, tooltipDirection }: Props) => {

  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);
  const { t } = useTranslation();

  const participants = React.useCallback((session) => mode === 'location' ?
    (session.prisoner + ' - ' + session.attendees[0].name + (' (' + session.organization + ')'))
    :
    (session.attendees[0].name + ' - ' + session.prisoner + (' (' + session.location + ')'))
  , [mode]);

  return (
    <div className={ 'tooltip ' + tooltipDirection }>
      { sessions?.length ?
        <ul>
          { sessions?.map(session =>
            <li key={ session.gid } className={ session.status === 'REJECTED' ? 'crossed' : undefined }>
              <strong>{ session.room }</strong>
              { ': ' + participants(session) }
            </li>
          )}
        </ul>
        : t('no-planned-sessions')
      }
    </div>
  );
};

export default TooltipContent;
