import * as React from 'react';
import { Provider } from 'react-redux';
import Main from './screens/Main';
import { PersistGate } from 'redux-persist/integration/react';
import { getStore, getPersistor } from './configureStore';
// @ts-ignore
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-GB';
import cs from 'date-fns/locale/cs';
import './assets/css/main.css';

const App: React.FC = () => {

  React.useEffect(() => {
    registerLocale('en', en);
    registerLocale('cs', cs);
    setDefaultLocale('cs');
  }, []);

  return (
    <Provider store={ getStore() }>
      <PersistGate loading={ null } persistor={ getPersistor() }>
        <Main/>
      </PersistGate>
    </Provider>
  );
};

export default App;
