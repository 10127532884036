import * as React from 'react';
import { WorkingHours } from './Calendar';
import utils from '../../utils';
import { SessionsResponse } from '../../../services/scheduler';
import Tooltip from './Tooltip';
import { TooltipDirection } from './Day';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/reducers/index';
import { Mode } from '../../../redux/reducers/general';
import CapacityIndicator from './CapacityIndicator';

interface Props {
  day: number;
  hour: number;
  minute?: number;
  workingHours?: WorkingHours[];
  locationSessions: SessionsResponse | undefined;
  organizationSessions: SessionsResponse | undefined;
  tooltipDirection: TooltipDirection;
  numberOfLocationRooms: number;
  numberOfOrganizationRooms: number;
  onCreate?: (date: Date) => void;
}

const hourToInterval = (hour: number, minute = 0): string => {
  const now = new Date();
  now.setHours(hour);
  now.setMinutes(minute);
  now.setSeconds(0);
  now.setMilliseconds(0);
  return utils.formatTime(now.getTime());
}

const toDate = (day: number, hour: number, minute = 0): Date => {
  const res = new Date(day);
  res.setHours(hour);
  res.setMinutes(minute);
  res.setSeconds(0);
  res.setMilliseconds(0);
  return res;
}

const HalfHour: React.FC<Props> = ({ day, hour, minute = 0, workingHours, locationSessions, organizationSessions, tooltipDirection, numberOfLocationRooms, numberOfOrganizationRooms, onCreate }: Props) => {

  const dayOfWeek = React.useMemo(() => utils.dayOfWeek(new Date(day)) + 1, [day]);
  const date = React.useMemo(() => toDate(day, hour, minute), [day, hour, minute]);
  const isInPast = React.useMemo(() => date.getTime() + (1800000) < Date.now(), [date]);
  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);

  const isInWorkingHours = React.useMemo(() => {
    if (!workingHours || isInPast) {
      return false;
    }
    const time = hour + (minute / 60);
    let result = true;
    workingHours.forEach(wh => {
      const whOfDay = wh[dayOfWeek];
      if (!whOfDay) {
        result = false;
      } else {
        const r1 = whOfDay.some(whInt => whInt.start <= time && whInt.end >= (time + 0.5));
        result = result && r1;
      }
    });
    return result;
  }, [workingHours, isInPast, hour, minute, dayOfWeek]);

  const locationSessionsInThisSlot = React.useMemo(() => {
    const res: SessionsResponse = [];
    const slotStart = date.getTime();
    const slotEnd = slotStart + 1800000;
    locationSessions?.forEach(session => {
      const start = new Date(session.startAt).getTime();
      const end = new Date(session.endAt).getTime();
      if (slotEnd > start && slotStart < end) {
        res.push(session);
      }
    });
    return res.length ? res : undefined;
  }, [locationSessions, date]);

  const organizationSessionsInThisSlot = React.useMemo(() => {
    const res: SessionsResponse = [];
    const slotStart = date.getTime();
    const slotEnd = slotStart + 1800000;
    organizationSessions?.forEach(session => {
      const start = new Date(session.startAt).getTime();
      const end = new Date(session.endAt).getTime();
      if (slotEnd > start && slotStart < end) {
        res.push(session);
      }
    });
    return res.length ? res : undefined;
  }, [organizationSessions, date]);

  const numberOfLocationSessions = React.useMemo(() => locationSessionsInThisSlot?.filter(s => s.status !== 'REJECTED').length || 0, [locationSessionsInThisSlot]);
  const numberOfOrganizationSessions = React.useMemo(() => organizationSessionsInThisSlot?.filter(s => s.status !== 'REJECTED').length || 0, [organizationSessionsInThisSlot]);

  const cls = React.useMemo(() => {
    const cls1 = numberOfLocationSessions ? (numberOfLocationRooms > numberOfLocationSessions ? 1 : 2) : 0;
    const cls2 = numberOfOrganizationSessions && numberOfOrganizationRooms ? (numberOfOrganizationRooms > numberOfOrganizationSessions ? 1 : 2) : 0;
    const clsMax = Math.max(cls1, cls2);
    return clsMax > 0 ? (clsMax > 1 ? 'full' : 'partial') : '';
  }, [numberOfLocationSessions, numberOfLocationRooms, numberOfOrganizationSessions, numberOfOrganizationRooms]);

  const handleCreate = React.useCallback(() => {
    onCreate?.(date);
  }, [date, onCreate]);

  return (
    <Tooltip sessions={ mode === 'location' ? locationSessionsInThisSlot : organizationSessionsInThisSlot } tooltipDirection={ tooltipDirection } >
      <div className={ 'half-hour' + (isInWorkingHours ? ' ' : ' disabled ') + cls }>
        <CapacityIndicator
          locationSessions={ numberOfLocationSessions }
          organizationSessions={ numberOfOrganizationSessions }
          locationRooms={ numberOfLocationRooms }
          organizationRooms={ numberOfOrganizationRooms }
        />
        { hourToInterval(hour, minute) }
        { onCreate && isInWorkingHours && cls !== 'full' ? <button className='plus' onClick={ handleCreate }>+</button> : null }
      </div>
    </Tooltip>
  );
};

export default HalfHour;
