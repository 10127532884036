import { doFetch, doFetchWithToken } from './service';
import { Mode } from '../redux/reducers/general';

const url = '/identity';

export type Claim = 'stats' | 'location' | 'organization' | 'attendee' | 'room' | 'cal' | 'ovr' | 'admin' | 'log';

export interface Token {
  expiration: string;
  token: string;
  name?: string;
  claims?: Array<Claim>;
  profile?: Profile;
}

export interface Group {
  id: string;
  name: string;
  type: 'KM' | 'OJ';
}

export interface TokenWithGroups extends Token {
  groups: Array<Group>;
}

export interface Profile {
  displayName: string;
  email: string;
  photo?: string | null;
  preferredLanguage?: string | null;
}

interface AadLoginResponse extends TokenWithGroups {
  profile: Profile
}

const identity = {

  connect: (name: string, passCode: string): Promise<Token> => {
    return doFetch<Token>(url + '/connect', { name, passCode }, { method: 'POST' });
  },

  connectAttendee: (name: string, passCode: string): Promise<Token> => {
    return doFetch<Token>(url + '/connect/attendee', { name, passCode }, { method: 'POST' });
  },

  connectRoom: (name: string, passCode: string): Promise<Token> => {
    return doFetch<Token>(url + '/connect/room', { name, passCode }, { method: 'POST' });
  },

  connectLocation: (name: string, passCode: string): Promise<TokenWithGroups> => {
    return doFetch<TokenWithGroups>(url + '/connect/location', { name, passCode }, { method: 'POST' });
  },

  connectOrganization: (name: string, passCode: string): Promise<TokenWithGroups> => {
    return doFetch<TokenWithGroups>(url + '/connect/organization', { name, passCode }, { method: 'POST' });
  },

  impersonateAsAttendee: (tokenType: Mode, attendeeGuid: string): Promise<Token> => {
    return doFetchWithToken<Token>(tokenType, url + '/token/impersonateasattendee/' + attendeeGuid, {}, { method: 'POST' });
  },

  impersonateAsSession: (tokenType: Mode, sessionGuid: string): Promise<Token> => {
    return doFetchWithToken<Token>(tokenType, url + '/token/impersonateassession/' + sessionGuid, {}, { method: 'POST' });
  },

  adLogin: (accessToken: string, room?: string | null): Promise<AadLoginResponse> => {
    return doFetch<AadLoginResponse>(url + '/azuread/login' + (room ? '?room=' + room : ''), { accessToken, room }, { method: 'POST' });
  },

  switchLocation: (tokenType: Mode, locationGuid: string): Promise<TokenWithGroups> => {
    return doFetchWithToken<TokenWithGroups>(tokenType, url + '/token/switchlocation/' + locationGuid, {}, { method: 'POST' });
  },

  impersonateAsRoom: (tokenType: Mode, roomGuid: string): Promise<TokenWithGroups> => {
    return doFetchWithToken<TokenWithGroups>(tokenType, url + '/token/impersonateasroom/' + roomGuid, {}, { method: 'POST' });
  }

}

export default identity;
