import * as React from 'react';
import { State } from '../../redux/reducers/index';
import { useSelector, useDispatch } from 'react-redux';
import { setLang } from '../../redux/actions/general';
import ImgCs from '../../assets/images/flags/cs.svg';
import ImgEn from '../../assets/images/flags/en.svg';

const options = [
  { value: 'cs', label: 'česky', src: ImgCs },
  { value: 'en', label: 'english', src: ImgEn }
]

const LanguageSelection: React.FC = () => {

  const lang = useSelector<State, string | undefined>(state => state.general.lang) ?? 'cs';
  const dispatch = useDispatch();

  const handleChange = React.useCallback(l => {
    dispatch(setLang(l));
  }, [dispatch]);

  return (
    <div className='languages'>
      { options.map(o =>
        <img
          key={ o.value }
          role='button'
          src={ o.src }
          title={ o.label }
          alt={ o.label }
          className={ lang === o.value ? 'selected' : undefined }
          width='auto'
          height='20'
          onClick={ handleChange.bind(null, o.value) }
        />
      ) }
    </div>
  )
};

export default LanguageSelection;
