import * as React from 'react';
import Select from 'react-select';
import './dropdown.css';
import { useTranslation } from 'react-i18next';

export type DropDownOption = {
  value: string,
  label: string
}

interface Props {
  label?: string;
  required?: boolean;
  options: Array<DropDownOption>;
  placeholder?: string;
  noOptionsText?: string;
  disabled?: boolean;
  value?: string,
  validate?: boolean;
  onChange: (value: string | undefined) => void
}

const noStyles = new Proxy(
  {},
  { get: () => () => ({}) }
);

const DropDown: React.FC<Props> = ({ label, required, options, placeholder, noOptionsText, disabled, value, validate, onChange }: Props) => {

  const [val, setVal] = React.useState(value);
  const valOption = React.useMemo(() => options.find(o => o.value === val), [val, options]);
  const { t } = useTranslation();

  React.useEffect(() => {
    setVal(value);
  }, [value]);

  const handleChange = React.useCallback((option) => {
    if (option.value !== val) {
      setVal(option.value);
      onChange(option.value || undefined);
    }
  }, [onChange, val]);

  return (
    <div className='row'>
      { label ? <label>{ label }{ required ? <span style={{ color: 'red' }}> *</span> : null }</label> : null }
      <Select
        className={ 'dropdown-container' + (validate && required && !val ? ' error' : '') }
        classNamePrefix='dropdown'
        options={ options }
        placeholder={ placeholder }
        isDisabled={ disabled }
        isSearchable={ false }
        captureMenuScroll={ false }
        noOptionsMessage={ () => noOptionsText ?? t('no-options') }
        value={ valOption }
        styles={ noStyles }
        onChange={ handleChange }
      />
    </div>
  )
}

export default DropDown;
