import { combineReducers, Reducer } from 'redux';
import { GeneralState, generalReducer } from './general';
import { GeneralTypes} from '../actions/general';

export interface State {
  general: GeneralState;
}

type AppTypes = GeneralTypes

export const reducer: Reducer<State, AppTypes> = combineReducers({
  general: generalReducer
});
