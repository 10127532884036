import * as React from 'react';
import { useLocation, useNavigate } from 'react-router';
import qs from 'query-string';
import { useDispatch } from 'react-redux';
import { setSessionToken, setMode } from '../../redux/actions/general';
import identity from "../../services/identity";
import Form from '../common/Form';
import InputBox from '../common/InputBox';
import Button from '../common/Button';
import ButtonRow from '../common/ButtonRow';
import { useTranslation } from 'react-i18next';

const AttendeeLogin: React.FC = () => {

  const [passCode, setPassCode] = React.useState<string>('');
  const [attendeeId, setAttendeeId] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(setSessionToken('attendee'));
    dispatch(setMode('attendee'));
    const aId = qs.parse(location.search).id;
    if (aId) {
      const g = Array.isArray(aId) ? aId[0] : aId;
      setAttendeeId(g ?? '');
    }
  }, [location.search, dispatch]);

  const reset = React.useCallback(() => {
    setError(true);
    setLoading(false);
    dispatch(setSessionToken('attendee'));
  }, [dispatch]);

  const handleClick = React.useCallback(() => {
    if (attendeeId && passCode) {
      setLoading(true);
      setError(false);
      identity.connectAttendee(attendeeId, passCode).then(
        res => {
          if (res) {
            setLoading(false);
            dispatch(setSessionToken('attendee', res));
            history('/calling');
          } else {
            reset();
          }
        },
        reset
      );
    }
  }, [attendeeId, passCode, dispatch, history, reset]);

  return (
    <Form type='small' onSubmit={ handleClick }>
      <InputBox label={ t('attendee-id') } id='attendeeId' autoComplete='username' required value={ attendeeId } onChange={ setAttendeeId } />
      <InputBox label={ t('password') } type='password' id='attendeePassCode' autoComplete='current-password' required value={ passCode } onChange={ setPassCode } />
      <ButtonRow>
        <Button loading={ loading } disabled={ !attendeeId || !passCode || loading } error={ error ? t('login-error') : undefined }>{ t('login') }</Button>
      </ButtonRow>
    </Form>
  );
};

export default AttendeeLogin;
