import * as React from 'react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';

interface Props {
  href?: string;
  loading?: boolean;
  error?: boolean;
  grey?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

const MenuItem: React.FC<Props> = ({ href, loading, error, grey, children, onClick }: Props) => {

  const className = React.useMemo(() => (grey ? 'group-item animated-item grey' : 'group-item animated-item') + (error ? ' error' : ''), [error, grey]);

  const handleClick = React.useCallback(() => {
    onClick?.();
  }, [onClick]);

  return href ?
    <Link to={ href } className={ className }>
      { loading ? <Spinner /> : children }
    </Link>
    :
    <div role='button' className={ className } onClick={ handleClick }>{ loading ? <Spinner /> : children }</div>
};

export default MenuItem;
