import * as React from 'react';
import utils from '../utils';

interface Props {
  zero: Date;
  onZero?: () => void;
}

interface Duration {
  hours: number;
  minutes: number;
  seconds: number;
}

const msToDuration = (ms: number): Duration => {
  if (ms <= 0) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const msMinusHours = ms - hours * (60 * 60 * 1000);
  const minutes = Math.floor(msMinusHours / (60 * 1000));
  const msMinusMinutes = msMinusHours - minutes * (60 * 1000);
  const seconds = Math.floor(msMinusMinutes / 1000);
  return { hours, minutes, seconds };
}

const diff = (start: number): number => start ? Math.max(start - Date.now(), 0) : 0;

const Countdown: React.FC<Props> = ({ zero, onZero }: Props) => {

  const zeroTime = zero.getTime();
  const [duration, setDuration] = React.useState(diff(zeroTime));
  const [zeroBlocked, setZeroBlocked] = React.useState(false);

  React.useEffect(() => {
    setZeroBlocked(zeroTime <= Date.now());
    setDuration(diff(zeroTime));
  }, [zeroTime]);

  const calculateDuration = React.useCallback(() => {
    setDuration(diff(zeroTime));
  }, [zeroTime]);

  React.useEffect(() => {
    if (duration === 0) {
      if (!zeroBlocked && onZero) {
        onZero();
        setZeroBlocked(true);
      }
    } else {
      const timer = setTimeout(calculateDuration, duration % 1000);
      return (): void => clearTimeout(timer);
    }
  }, [calculateDuration, duration, onZero, zeroBlocked]);

  const durationObject = msToDuration(duration + 999);
  const durationString = utils.twoDigits(durationObject.hours) + ':' + utils.twoDigits(durationObject.minutes) + ':' + utils.twoDigits(durationObject.seconds);

  return (durationString as unknown) as React.ReactElement;
};

export default Countdown;
