import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getStore } from '../configureStore';

i18n.use(initReactI18next).init({
  fallbackLng: 'cs',
  lng: getStore().getState().general?.lang ?? 'cs',
  resources: {
    cs: {
      translations: require('./locales/cs.json')
    },
    en: {
      translations: require('./locales/en.json')
    }
  },
  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['cs', 'en'];

export default i18n;
