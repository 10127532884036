import * as React from 'react';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/reducers/index';
import { Mode } from '../../../redux/reducers/general';

interface Props {
  locationSessions: number;
  organizationSessions: number;
  locationRooms: number;
  organizationRooms: number;
}

const CapacityIndicator: React.FC<Props> = ({ locationSessions, organizationSessions, locationRooms, organizationRooms }: Props) => {

  const mode = useSelector<State, Mode | undefined>(state => state.general.mode);
  const organizationFree = organizationRooms - organizationSessions;
  const locationFree = locationRooms - locationSessions;
  const minFree = Math.min(organizationFree, locationFree);
  const min = mode === 'location' ? (organizationRooms ? minFree : locationFree) : organizationFree;

  return (
    <>
      { min ?
        <div className='capacity'>{ Math.max(min, 0) }</div>
        :
        <>
          { mode === 'location' ? <div className={ 'capacity light' + (locationFree ? '' : ' full') } /> : null }
          { mode === 'location' && organizationRooms ? <div className={ 'capacity light second' + (organizationFree ? '' : ' full') } /> : null }
          { mode === 'organization' ? <div className={ 'capacity light' + (organizationFree ? '' : ' full') } /> : null }
        </>
      }
    </>
  );
};

export default CapacityIndicator;
