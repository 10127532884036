import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  refreshing?: boolean;
  disabled?: boolean;
  onClick?: () => Promise<unknown> | void;
}

const RefreshButton: React.FC<Props> = ({ refreshing, disabled, onClick }: Props) => {

  const [refreshing2, setRefreshing2] = React.useState(false);
  const { t } = useTranslation();

  const handleClick = React.useCallback(() => {
    setRefreshing2(true)
    const p = onClick?.();
    if (p && p instanceof Promise) {
      p.finally(() => setRefreshing2(false));
    } else {
      setTimeout(() => setRefreshing2(false), 500);
    }
  }, [onClick]);

  return (
    <button className='primary' disabled={ disabled } onClick={ handleClick }>
      <div>
        <svg viewBox="0 0 512 512" className={ refreshing || refreshing2 ? 'rotating' : undefined }>
          <path d="M436.6,75.4C390.1,28.9,326.7,0,256,0C114.5,0,0,114.5,0,256s114.5,256,256,256 c119.2,0,218.8-81.9,247.6-191.8h-67c-26.1,74.5-96.8,127.5-180.6,127.5c-106.1,0-191.8-85.6-191.8-191.8S149.9,64.2,256,64.2	c53.1,0,100.5,22.3,135,56.8L287.7,224.3H512V0L436.6,75.4z" />
        </svg>
        { t('refresh') }
      </div>
    </button>
  )
}

export default RefreshButton;
