import * as React from 'react';

interface Props {
  children?: React.ReactNode;
}

const TableHeader: React.FC<Props> = ({ children }: Props) => {

  return (
    <thead>
      <tr>
        { children }
      </tr>
    </thead>
  )
}

export default TableHeader;
