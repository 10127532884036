import { doFetchWithToken, dateToJson } from './service';
import { Mode } from '../redux/reducers/general';

const url = '/scheduler/log';

export type LogItem = {
  sessionGid: string;
  eventDate: string;
  message: string;
}
export interface LogResponse {
  count: number;
  data: Array<LogItem>;
}

const log = {

  logBySession: (tokenType: Mode, sessionGid: string, pgNo?: number, pgSize?: number | null): Promise<LogResponse> => {
    return doFetchWithToken<LogResponse>(tokenType, url + '/bysession', { sessionGid, pgNo, pgSize });
  },

  logByLocation: (tokenType: Mode, fromDate: Date, locationGid: string, pgNo?: number | null, pgSize?: number | null): Promise<LogResponse> => {
    const fromDt = dateToJson(fromDate);
    return doFetchWithToken<LogResponse>(tokenType, url + '/bylocation', { fromDt, locationGid, pgNo, pgSize });
  },

  export: (tokenType: Mode, fromDate: Date, toDate: Date, locationGid: string): Promise<Response> => {
    const fromDt = dateToJson(fromDate);
    const toDt = dateToJson(toDate);
    return doFetchWithToken<Response>(tokenType, url + '/export', { fromDt, toDt, locationGid });
  }
}

export default log;
