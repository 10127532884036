import { Mode } from '../reducers/general';
import { Room } from '../../services/scheduler';
import { Group, Token, Profile } from '../../services/identity';
import { REHYDRATE } from 'redux-persist';
import { State } from '../reducers/index';

const SET_MODE = 'SET_MODE';
const SET_USERID = 'SET_USERID';
const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';
const SET_LOCATION = 'SET_LOCATION';
const SET_ORGANIZATION = 'SET_ORGANIZATION';
const SET_ORGANIZATION_GID = 'SET_ORGANIZATION_GID';
const SET_LOC_ORG_GID = 'SET_LOC_ORG_GID';
const SET_ROOM = 'SET_ROOM';
const SET_ROOMS = 'SET_ROOMS';
const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
const SET_CALL_TOKEN = 'SET_CALL_TOKEN';
const REFRESH_SESSION_LIST = 'REFRESH_SESSION_LIST';
const SET_LANG = 'SET_LANG';
const SET_GROUPS = 'SET_GROUPS';
const SET_GROUP = 'SET_GROUP';
const SET_IMPERSONATED = 'SET_IMPERSONATED';

interface SetModeAction {
  type: typeof SET_MODE;
  mode: Mode;
}

interface SetUserIdAction {
  type: typeof SET_USERID;
  userId: string;
}

interface SetDisplayNameAction {
  type: typeof SET_DISPLAY_NAME;
  displayName?: string;
}

interface SetLocationAction {
  type: typeof SET_LOCATION;
  location: string | undefined;
}

interface SetOrganizationAction {
  type: typeof SET_ORGANIZATION;
  organization: string | undefined;
}

interface SetOrganizationGidAction {
  type: typeof SET_ORGANIZATION_GID;
  organizationGid: string | undefined;
}

interface SetLocOrgGidAction {
  type: typeof SET_LOC_ORG_GID;
  locOrgGid: string | undefined;
}

interface SetRoomAction {
  type: typeof SET_ROOM;
  room: string | undefined;
}

interface SetRoomsAction {
  type: typeof SET_ROOMS;
  rooms: Array<Room>;
}

interface SetSessionTokenAction {
  type: typeof SET_SESSION_TOKEN;
  tokenType: Mode;
  token?: Token;
  profile?: Profile;
}

interface SetCallTokenAction {
  type: typeof SET_CALL_TOKEN;
  tokenType: Mode;
  token?: string;
  expiration?: string;
  attendeeGuid?: string;
}

interface RefreshSessionListAction {
  type: typeof REFRESH_SESSION_LIST;
}

interface SetLangAction {
  type: typeof SET_LANG;
  lang: string;
}

interface RehydrateAction {
  type: typeof REHYDRATE;
  payload: State;
}

interface SetGroupsAction {
  type: typeof SET_GROUPS;
  groups: Array<Group>;
  group?: Group;
}

interface SetGroupAction {
  type: typeof SET_GROUP;
  group?: Group;
}

interface SetImpersonatedAction {
  type: typeof SET_IMPERSONATED;
  impersonated: boolean;
}

export const setMode = (mode: Mode): SetModeAction => {
  return {
    type: SET_MODE,
    mode
  };
};

export const setUserId = (userId: string): SetUserIdAction => {
  return {
    type: SET_USERID,
    userId
  };
};

export const setDisplayName = (displayName: string): SetDisplayNameAction => {
  return {
    type: SET_DISPLAY_NAME,
    displayName
  };
};

export const setLocation = (location?: string): SetLocationAction => {
  return {
    type: SET_LOCATION,
    location
  };
};

export const setOrganization = (organization?: string): SetOrganizationAction => {
  return {
    type: SET_ORGANIZATION,
    organization
  };
};

export const setOrganizationGid = (organizationGid?: string): SetOrganizationGidAction => {
  return {
    type: SET_ORGANIZATION_GID,
    organizationGid
  };
};

export const setLocOrgGid = (locOrgGid?: string): SetLocOrgGidAction => {
  return {
    type: SET_LOC_ORG_GID,
    locOrgGid
  };
};

export const setRoom = (room?: string): SetRoomAction => {
  return {
    type: SET_ROOM,
    room
  };
};

export const setRooms = (rooms: Array<Room>): SetRoomsAction => {
  return {
    type: SET_ROOMS,
    rooms
  };
};

export const setSessionToken = (tokenType: Mode, token?: Token, profile?: Profile): SetSessionTokenAction => {
  return {
    type: SET_SESSION_TOKEN,
    tokenType,
    token,
    profile
  };
};

export const setCallToken = (tokenType: Mode, token?: string, expiration?: string, attendeeGuid?: string): SetCallTokenAction => {
  return {
    type: SET_CALL_TOKEN,
    tokenType,
    token,
    expiration,
    attendeeGuid
  };
};

export const refreshSessionList = (): RefreshSessionListAction => {
  return {
    type: REFRESH_SESSION_LIST
  };
};

export const setLang = (lang: string): SetLangAction => {
  return {
    type: SET_LANG,
    lang
  };
};

export const setGroups = (groups: Array<Group>, group?: Group): SetGroupsAction => {
  return {
    type: SET_GROUPS,
    groups,
    group
  };
};

export const setGroup = (group?: Group): SetGroupAction => {
  return {
    type: SET_GROUP,
    group
  };
};

export const setImpersonated = (impersonated = false): SetImpersonatedAction => {
  return {
    type: SET_IMPERSONATED,
    impersonated
  };
};

export { SET_MODE, SET_USERID, SET_DISPLAY_NAME, SET_SESSION_TOKEN, SET_CALL_TOKEN, SET_LOCATION, SET_ORGANIZATION, SET_ORGANIZATION_GID, SET_LOC_ORG_GID, SET_ROOM, SET_ROOMS, REFRESH_SESSION_LIST, SET_LANG, SET_GROUPS, SET_GROUP, SET_IMPERSONATED };

export type GeneralTypes = SetModeAction | SetUserIdAction | SetDisplayNameAction | SetSessionTokenAction | SetCallTokenAction | SetLocationAction | SetOrganizationAction | SetOrganizationGidAction | SetLocOrgGidAction | SetRoomAction | SetRoomsAction | RefreshSessionListAction | SetLangAction | RehydrateAction | SetGroupsAction | SetGroupAction | SetImpersonatedAction;
