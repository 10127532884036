import * as React from 'react';

interface Props {
  type?: 'small' | 'large',
  children: React.ReactNode;
  onSubmit?: () => void;
}

const Form: React.FC<Props> = ({ type, children, onSubmit }: Props) => {

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();
    onSubmit?.();
  }, [onSubmit]);

  return (
    <div className='form-content'>
      <form className={ 'form ' + (type || '') } onSubmit={ handleSubmit }>
        { children }
      </form>
    </div>
  )
}

export default Form;
