import * as React from 'react';
import Page from "../common/Page";
import { useDispatch, useSelector } from 'react-redux';
import { setSessionToken, setMode, setGroups, setImpersonated } from '../../redux/actions/general';
import { useTranslation } from 'react-i18next';
import identity from '../../services/identity';
import { State } from '../../redux/reducers/index';
import { GeneralState } from '../../redux/reducers/general';
import Spinner from '../common/Spinner';

const Call: React.FC = () => {

  const { mode, sessionToken, room, lang } = useSelector<State, GeneralState>(state => state.general);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const token = React.useMemo(() => sessionToken?.[mode ?? 'room'], [mode, sessionToken]);
  const server = location.hostname === 'localhost' ? 'http://localhost:3001' : '';

  React.useEffect(() => {
    if ((mode === 'location' || mode === 'organization') && room) {
      identity.impersonateAsRoom(mode, room).then(
        res => {
          dispatch(setSessionToken('room', res));
          dispatch(setMode('room'));
          dispatch(setImpersonated(true));
        }
      );
    }
  }, [dispatch, mode, room]);

  React.useEffect(() => {
    if (mode === 'room' || mode === 'attendee') {
      dispatch(setSessionToken('location'));
      dispatch(setSessionToken('organization'));
      dispatch(setSessionToken(mode === 'room' ? 'attendee' : 'room'));
      dispatch(setGroups([]));
    }
  }, [dispatch, mode]);

  return (
    <Page title={ t('online-sessions') } homeUrl='/' fullHeight>
      { token && (mode === 'room' || mode === 'attendee') ?
        <iframe className='call-iframe' src={ server + '/call/' + mode + '/wait?lang=' + lang + '&token=' + token.token + '&exp=' + token.expiration } allow='camera;microphone' />
        :
        <div className='spinner-center'><Spinner /></div>
      }
    </Page>
  );
};

export default Call;
