import * as React from "react";
import { NavLink } from "react-router-dom";

interface Props {
  children: React.ReactNode | null;
  id: string;
  onClick: () => void;
}

const TabHeader : React.FC<Props> = ({ children, id, onClick } : Props) => {

  return (
    <NavLink to={ id } className={ ({ isActive }) => (isActive ? 'tab-header is-active' : 'tab-header') } onClick={ onClick }>
      { children }
    </NavLink>
  );
}

export default TabHeader;
