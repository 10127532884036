import * as React from 'react';

export interface Props {
  id: string;
  title?: string;
  titleKey?: string;
  hidden?: boolean;
  children?: React.ReactNode;
}

const Tab: React.FC<Props> = ({ id, children }: Props) => {
  return children ? <div id={ id }>{ children }</div> : null;
};

export default Tab;
