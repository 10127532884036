import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  children: string;
}

const SessionRenderer: React.FC<Props> = ({ children }: Props) => {

  const firstPart = children.split('-')[0];

  return (
    <Link to={ location.pathname + '?sessionGid=' + children } title={ children }>{ firstPart + '...' }</Link>
  )
}

export default SessionRenderer;
