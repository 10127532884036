import * as React from 'react';

interface Props {
  children?: React.ReactNode;
}

const Heading: React.FC<Props> = ({ children }: Props) => {

  return (
    <h3>{ children }</h3>
  )
}

export default Heading;
