import * as React from 'react';
import { useSelector } from "react-redux";
import { State } from '../../redux/reducers/index';
import { GeneralState } from '../../redux/reducers/general';
import LocationItem from './LocationItem';
import utils from '../utils';
import { Group } from '../../services/identity';

type Props = {
  onClose: () => void;
}

const LocationList: React.FC<Props> = ({ onClose }: Props) => {

  const { groups, group } = useSelector<State, GeneralState>(state => state.general);
  const sortedGroups = React.useMemo(() => groups?.slice()?.sort(utils.stringSorter<Group>('name')), [groups]);

  return groups ?
    <div className='location-list'>
      { sortedGroups?.map(g =>
        <LocationItem
          key={ g.id }
          group={ g }
          selected={ g.id === group?.id }
          onClose={ onClose }
        />
      ) }
    </div>
    : null
};

export default LocationList;
