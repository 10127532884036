import * as React from 'react';
import '../../../assets/css/calendar.css';
import CalendarControls from './CalendarControls';
import HeaderControls from '../HeaderControls';

interface Props {
  refreshing?: boolean;
  children?: React.ReactNode;
  onBack: () => void;
  onToday: () => void;
  onForward: () => void;
  onRefresh: () => Promise<unknown> | void;
}

const CalendarHeader: React.FC<Props> = ({ refreshing, children, onBack, onToday, onForward, onRefresh }: Props) => {

  return (
      <HeaderControls left={ children } refreshing={ refreshing } onRefresh={ onRefresh }>
        <CalendarControls onBack={ onBack } onForward={ onForward } onToday={ onToday } />
      </HeaderControls>
  );
};

export default CalendarHeader;
