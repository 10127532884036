import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Page from '../common/Page';
import { setMode } from '../../redux/actions/general';
import { useDispatch, useSelector } from "react-redux";
import { State } from '../../redux/reducers/index';
import { useTranslation } from 'react-i18next';

const Room: React.FC = () => {

  const dispatch = useDispatch();
  const room = useSelector<State, string | undefined>(state => state.general.room);
  const { t } = useTranslation();

  React.useEffect(() => {
    dispatch(setMode('room'));
  }, [dispatch]);

  return (
    <Page title={ t('room') + (room ? ' ' + room : '') }>
      <Outlet />
    </Page>
  )
};

export default Room;
