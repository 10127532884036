import { Configuration, LogLevel } from "@azure/msal-browser";

// VS App (B2C) Registration T: 1bec7b46-77cf-45bc-a896-6a7ad5c5fc74; C: 4cdfee7a-48ac-42b1-9315-f29b26dd382a
// VS App       Registration T: 3b341c6d-f4e0-4bb9-a2f1-455007a2965c; C: 7842b295-c586-469f-b7f6-93247adbe66e

const server = window.location.hostname;
// const clientId = server === 'localhost' ? '83bea004-1a62-41d7-a1f7-7091188493e9' : (server === 'videodemomachac.azurewebsites.net' ? 'fcf3c4bf-05a1-49bc-bb8a-c71609eaf4a7' : '4cdfee7a-48ac-42b1-9315-f29b26dd382a');
// const tenantId = server === 'localhost' ? '94af9b71-2001-4486-87fe-0ddabb50756d' : (server === 'videodemomachac.azurewebsites.net' ? '94af9b71-2001-4486-87fe-0ddabb50756d' : '1bec7b46-77cf-45bc-a896-6a7ad5c5fc74');
const clientId = server === 'localhost' ? 'cf9fa1a6-38e4-4ac8-98b0-2f0765828abf' : (server === 'videodemomachac.azurewebsites.net' ? 'fcf3c4bf-05a1-49bc-bb8a-c71609eaf4a7' : '4cdfee7a-48ac-42b1-9315-f29b26dd382a');
const tenantId = server === 'localhost' ? '1bec7b46-77cf-45bc-a896-6a7ad5c5fc74' : (server === 'videodemomachac.azurewebsites.net' ? '94af9b71-2001-4486-87fe-0ddabb50756d' : '1bec7b46-77cf-45bc-a896-6a7ad5c5fc74');

export const MsalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: 'https://login.microsoftonline.com/' + tenantId + '/'
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};
