import * as React from 'react';

interface Props {
  id?: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
  onChange: (value: boolean) => void;
}

const CheckBox: React.FC<Props> = ({ id, label, disabled, checked, onChange }: Props) => {

  const handleChange = React.useCallback((event) => {
    onChange(event?.target?.checked || false);
  }, [onChange]);

  return (
    <div className='row checkbox'>
      <input
        id={ id }
        name={ id }
        disabled={ disabled }
        type='checkbox'
        checked={ checked }
        onChange={ handleChange }
      />
      <label htmlFor={ id }>{ label }</label>
    </div>
  )
}

export default CheckBox;
