import * as React from 'react';
import Button from '../common/Button';
import { useTranslation } from 'react-i18next';
import TimeInputBox from '../common/TimeInputBox';

interface Props {
  label?: string;
  from?: string | undefined;
  to?: string | undefined;
  day: number;
  rank: number;
  numberOfItems?: number;
  onChange: (day: number, rank: number, value: { from: string, to: string }) => void;
  onAdd: (day: number, rank: number) => void;
  onRemove: (day: number, rank: number) => void;
  onChangeValid?: (valid: boolean) => void;
}

const WorkingHoursDay: React.FC<Props> = ({ label, from, to, day, rank, onChange, onAdd, onRemove, onChangeValid }: Props) => {

  const { t } = useTranslation();
  const [fromValid, setFromValid] = React.useState<boolean>(true);
  const [toValid, setToValid] = React.useState<boolean>(true);
  const valid = React.useMemo(() => fromValid && toValid, [fromValid, toValid]);

  React.useEffect(() => {
    onChangeValid?.(valid);
  }, [valid, onChangeValid]);

  const handleChangeFrom = React.useCallback(from => {
    onChange(day, rank, { from, to: to ?? '' });
  }, [day, onChange, rank, to]);

  const handleChangeTo = React.useCallback(to => {
    onChange(day, rank, { from: from ?? '', to });
  }, [day, from, onChange, rank]);

  const handleAdd = React.useCallback(() => {
    onAdd(day, rank);
  }, [day, onAdd, rank]);

  const handleRemove = React.useCallback(() => {
    onRemove(day, rank);
  }, [day, onRemove, rank]);

  return (
    <div className='wh-row'>
        <div className='label'>{ label }</div>
        <TimeInputBox id={ 'from-' + day + '-' + rank } placeholder={ t('from') } value={ from } maxValue={ to } onChange={ handleChangeFrom } onChangeValid={ setFromValid } />
        <TimeInputBox id={ 'to-' + day + '-' + rank } placeholder={ t('to') } value={ to } minValue={ from } onChange={ handleChangeTo } onChangeValid={ setToValid } />
        <Button disabled={ !from && !to } title={ t('add') } onClick={ handleAdd }>+</Button>
        <Button disabled={ !from && !to && rank === 0 } title={ t('remove') } onClick={ handleRemove }>-</Button>
    </div>
  );
};

export default WorkingHoursDay;
