import { doFetchWithCallToken, doFetchWithToken, doFetchWithImpersonateAsSession } from './service';
import { Mode } from '../redux/reducers/general';

const url = '/callmanager';

interface CallGroupResponse {
  result?: string;
  token: string;
  recording: boolean;
  user: string;
}

const callGroup = {

  callGroup: (tokenType: Mode, attendeeGuid: string, sessiongid: string, forceFetchNewCallToken = false): Promise<CallGroupResponse> => {
    // return new Promise((resolve, reject) => {
    //   setTimeout(() => reject('sdfdsf'), 3000);
    // });
    return tokenType === 'attendee' ?
      doFetchWithToken<CallGroupResponse>(tokenType, url + '/callgroup', { sessiongid })
      :
      doFetchWithCallToken<CallGroupResponse>(tokenType, attendeeGuid, forceFetchNewCallToken, url + '/callgroup', { sessiongid });
  },

  callGroupAction: (tokenType: Mode, attendeeGuid: string, sessiongid: string, action: number): Promise<void> => {
    return doFetchWithCallToken<void>(tokenType, attendeeGuid, false, url + '/callgroupaction', { sessiongid, action }, { method: 'POST' });
  },

  stopSession: (tokenType: Mode, sessiongid: string): Promise<void> => {
    return doFetchWithImpersonateAsSession<void>(tokenType, sessiongid, url + '/callgroupaction', { sessiongid, action: 0 }, { method: 'POST' });
  }
}

export default callGroup;
