import { doFetchWithToken, dateToJson } from './service';
import { Mode } from '../redux/reducers/general';
import { WorkingHours } from '../screens/common/calendar/Calendar';

const url = '/scheduler';

export interface FutureEvent {
  sessionGid: string;
  attendeeGid: string;
  isPrisoner: boolean;
  sessionName: string;
  name: string;
  serverUTCTime: string;
  startsAt: string;
  endsAt: string;
  startsAtWithOffset: string;
  endsAtWithOffset: string;
}

interface ScheduledEvent {
  sessionGid: string;
  withRecording: boolean;
  startsAt: string;
  endsAt: string;
}

interface Attendee {
  gid: string;
  name: string;
  attendeeLink: string;
}

export type SessionStatus = 'APPROVED' | 'REJECTED';
export type SessionSchStatus = 'SCHEDULED' | 'STARTED' | 'CREATED';
export interface Session {
  gid: string;
  status: SessionStatus;
  schStatus: SessionSchStatus;
  created: string;
  approved: string;
  approvedBy: string;
  prisoner: string;
  room: string;
  location: string;
  organization: string;
  roomLink: string;
  startAt: string;
  endAt: string;
  attendees: Attendee[];
  op?: Array<string>;
  message: string | null;
}

export interface Room {
  gid: string;
  name: string;
  code: string;
  isActive: boolean;
}

interface Location {
  gid: string;
  name: string;
  isActive: boolean;
  authority: string;
  email: string;
  workingHours: {
    [day: number]: Array<string>;
  }
}

export interface Organization {
  gid: string;
  [name: string]: string;
}

type Stats = {
  sessionGid: string;
  status: SessionStatus;
  startAt: string;
  location: string;
  organization: string;
  visitor: string;
  prisoner: string;
}

type FutureEventsResponse = Array<FutureEvent>
type ScheduledEventsResponse = Array<ScheduledEvent>
export type SessionsResponse = Array<Session>
export type RoomsResponse = Array<Room>
export type OrganizationsResponse = Array<Organization>
export type LocationsResponse = Array<Location>
type WorkingHoursResponse = {
  [dayOfWeek: string]: string[];
}
export type DaysOffResponse = Array<string>;
export type StatsResponse = Array<Stats>;

const addOffset = (orig: string, offset: number): string => {
  const ms = new Date(orig).getTime() + offset;
  return new Date(ms).toJSON();
}

const convertTime = (timeString: string): number => {
  const timeArr = timeString.split(':');
  return Number(timeArr[0]) + (Number(timeArr[1]) / 60);
}

const scheduler = {

  session: (prisoner: string, start: Date, duration: number, attendeeName: string, attendeeEmail: string | undefined, attendeePassCode: string | undefined, organizationGid: string | undefined, room: string, recording = true): Promise<void> => {
    const starts = dateToJson(start);
    return doFetchWithToken<void>(
      'location',
      url + '/session',
      { prisoner, starts, duration, recording, attendees: [{ name: attendeeName, email: attendeeEmail, passCode: attendeePassCode, organizationGid }], room },
      { method: 'PUT' }
    );
  },

  requestSession: (prisoner: string, start: Date, attendee: string, location: string, recording = true): Promise<void> => {
    const starts = dateToJson(start);
    return doFetchWithToken<void>(
      'organization',
      url + '/session/requestsession',
      { prisoner, starts, recording, attendee, location },
      { method: 'PUT' }
    );
  },

  approveSession: (gid: string): Promise<void> => {
    return doFetchWithToken<void>(
      'location',
      url + '/session/' + gid + '/approve',
      {},
      { method: 'POST' }
    );
  },

  rejectSession: (tokenType: Mode, gid: string, reason = 'Zrušeno'): Promise<void> => {
    return doFetchWithToken<void>(
      tokenType,
      url + '/session/' + gid + '/reject?message=' + reason,
      {},
      { method: 'POST' }
    );
  },

  futureEvents: (tokenType: Mode, max?: number): Promise<FutureEventsResponse> => {
    return doFetchWithToken<FutureEventsResponse>(
      tokenType,
      url + '/scheduler/futureevents',
      { max }
    ).then(
      events => {
        if (events?.length) {
          const offset = Date.now() - new Date(events[0].serverUTCTime).getTime();
          return events.map(e => ({ ...e, startsAtWithOffset: addOffset(e.startsAt, offset), endsAtWithOffset: addOffset(e.endsAt, offset) }));
        } else {
          return [];
        }
      }
    );
  },

  scheduledEvents: (pointInTime: Date): Promise<ScheduledEventsResponse> => {
    return doFetchWithToken<ScheduledEventsResponse>(
      'room',
      url + '/scheduler/scheduledevents',
      { pointInTime: dateToJson(pointInTime) }
    );
  },

  sessions: (tokenType: Mode, pointInTime?: Date): Promise<SessionsResponse> => {
    return doFetchWithToken<SessionsResponse>(
      tokenType,
      url + '/session/datatable',
      { pint: pointInTime ? dateToJson(pointInTime) : undefined }
    );
  },

  statistics: (tokenType: Mode, from?: string): Promise<StatsResponse> => {
    return doFetchWithToken<StatsResponse>(
      tokenType,
      url + '/statistics/datatable',
      { from }
    );
  },

  plannedSessions: (tokenType: Mode, pointInTime?: string, locationGid?: string, days?: number): Promise<SessionsResponse> => {
    return doFetchWithToken<SessionsResponse>(
      tokenType,
      url + '/calendar/plannedsessions',
      { locationGid, pint: pointInTime, days }
    );
  },

  rooms: (tokenType: Mode, locationGid?: string): Promise<RoomsResponse> => {
    return doFetchWithToken<RoomsResponse>(
      tokenType,
      url + '/room',
      { locationGid }
    );
  },

  locations: (): Promise<LocationsResponse> => {
    return doFetchWithToken<LocationsResponse>(
      'organization',
      url + '/location'
    );
  },

  organizations: (): Promise<OrganizationsResponse> => {
    return doFetchWithToken<OrganizationsResponse>(
      'location',
      url + '/location/organizations'
    );
  },

  locationWorkingHours: (tokenType: Mode, locationGid?: string): Promise<WorkingHours> => {
    return doFetchWithToken<WorkingHoursResponse>(
      tokenType,
      url + '/calendar/workinghours',
      { locationGid }
    ).then(
      res => {
        const result: WorkingHours = {};
        Object.keys(res).forEach(key => {
          const whIntervals = res?.[key];
          result[Number.parseInt(key)] = whIntervals.map(interval => {
            const intArray = interval.split('-');
            return ({
              start: convertTime(intArray[0]),
              end: convertTime(intArray[1])
            });
          });
        });
        return result;
      }
    )
  },

  daysOff: (tokenType: Mode): Promise<DaysOffResponse> => {
    return doFetchWithToken<DaysOffResponse>(
      tokenType,
      url + '/calendar/daysoff'
    );
  },

  getLocation: (tokenType: Mode, locationGid: string): Promise<Location> => {
    return doFetchWithToken<Location>(tokenType, url + '/location/' + locationGid);
  },

  updateLocation: (tokenType: Mode, locationGid: string, name: string, authority: string, isActive: boolean, email: string | undefined, workingHours: { [day: number]: Array<string> }): Promise<void> => {
    return doFetchWithToken<void>(tokenType, url + '/location/' + locationGid, { name, authority, isActive, email, workingHours }, { method: 'POST' });
  }
}

export default scheduler;
