import * as React from 'react';
import '../../../assets/css/calendar.css';
import { SessionsResponse } from '../../../services/scheduler';
import TooltipContent from './TooltipContent';
import { TooltipDirection } from './Day';

interface Props {
  sessions: SessionsResponse | undefined;
  tooltipDirection: TooltipDirection;
  children: React.ReactElement;
}

const Tooltip: React.FC<Props> = ({ sessions, tooltipDirection, children }: Props) => {

  const [mouseOver, setMouseOver] = React.useState(false);
  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setOpen(mouseOver), 400);
    return () => clearTimeout(timer);
  }, [mouseOver]);

  const handleMouseEnter = React.useCallback(() => {
    setMouseOver(true);
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setMouseOver(false);
  }, []);

  return (
    <div className='half-hour-wrapper' onMouseEnter={ handleMouseEnter } onMouseLeave={ handleMouseLeave }>
      { children }
      { sessions && isOpen ?
        <TooltipContent sessions={ sessions } tooltipDirection={ tooltipDirection }/>
        : null
      }
    </div>
  );
};

export default Tooltip;
