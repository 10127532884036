import * as React from 'react';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';

interface Props {
  firstDay: number;
}

const hourMilis = 60 * 60 * 1000;
const dayMilis = 24 * hourMilis;
const days = Array(7).fill(0).map((x,i)=>i);
const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const DayHeaders: React.FC<Props> = ({ firstDay }: Props) => {

  const { t } = useTranslation();
  const now = Date.now();

  return (
    <div className='header'>
      { days.map(day =>
        <div
          key={ day }
          className={ 'day-header' + (-firstDay - day === 0 ? ' today' : '') }
        >
          <span>{ t(daysOfWeek[day]) }</span>{ utils.formatDate(dayMilis * (firstDay + day) + now) }
        </div>
      )}
    </div>
  );
};

export default DayHeaders;
