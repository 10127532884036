import * as React from 'react';
import log, { LogResponse } from '../../../services/log';
import Table from '../table/Table';
import CalendarControls from '../calendar/CalendarControls';
import { useTranslation } from 'react-i18next';
import utils from '../../utils';
import { useSelector } from 'react-redux';
import { State } from '../../../redux/reducers/index';
import DateTimeInputBox from '../DateTimeInputBox';
import SessionRenderer from './SessionRenderer';
import { useLocation, useNavigate } from 'react-router';
import Button from '../Button';
import DateTimeWithSecondsRenderer from '../renderers/DateTimeWithSecondsRenderer';
import { Mode } from '../../../redux/reducers/general';
import TableWrapper from '../table/TableWrapper';
import HeaderControls from '../HeaderControls';

const LogTable: React.FC = () => {

  const today = React.useMemo(() => {
    const now = new Date();
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
  }, []);
  const [logs, setLogs] = React.useState<LogResponse | undefined>();
  const [loading, setLoading] = React.useState(true);
  const [exportError, setExportError] = React.useState(false);
  const { t } = useTranslation();
  const [fromDate, setFromDate] = React.useState<Date>(today);
  const locationGid = useSelector<State, string | undefined>(state => state.general.locOrgGid);
  const mode = useSelector<State, Mode | undefined>(state => state.general.mode) ?? 'location';
  const location = useLocation();
  const navigate = useNavigate();
  const { page, sessionGid } = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    return { page: Number(params.get('page')), sessionGid: params.get('sessionGid') };
  }, [location.search]);

  const fetchLogsByLocation = React.useCallback((locationGid: string) => {
    setLoading(true);
    const cp = utils.makeCancelable(log.logByLocation(mode, fromDate, locationGid, page));
    cp.promise.then(
      res => {
        setLoading(false);
        setLogs(res);
      },
      () => {
        setLoading(false);
        setLogs({ count: 0, data: []});
        console.log('chyba pri nacitani logu');
      }
    );
    return cp;
  }, [fromDate, mode, page]);

  const fetchLogsBySession = React.useCallback((sessionGid: string) => {
    setLoading(true);
    const cp = utils.makeCancelable(log.logBySession(mode, sessionGid, page));
    cp.promise.then(
      res => {
        setLoading(false);
        setLogs(res);
      },
      () => {
        setLoading(false);
        setLogs({ count: 0, data: []});
        console.log('chyba pri nacitani logu');
      }
    );
    return cp;
  }, [mode, page]);

  const fetchLogs = React.useCallback(() => {
    if (sessionGid) {
      return fetchLogsBySession(sessionGid);
    } else if (locationGid) {
      return fetchLogsByLocation(locationGid);
    }
  }, [fetchLogsByLocation, fetchLogsBySession, locationGid, sessionGid]);

  React.useEffect(() => {
    return fetchLogs()?.cancel;
  }, [fetchLogs]);

  const handleToday = React.useCallback(() => {
    setFromDate(today);
  }, [today]);

  const handleBack = React.useCallback(() => {
    setFromDate(new Date(fromDate.getTime() - 7 * 24 * 60 * 60 * 1000));
  }, [fromDate]);

  const plusOneWeek = React.useCallback((d: Date) => {
    return new Date(d.getTime() + 7 * 24 * 60 * 60 * 1000);
  }, []);

  const handleForward = React.useCallback(() => {
    setFromDate(plusOneWeek(fromDate));
  }, [fromDate, plusOneWeek]);

  const handleChangeFromDate = React.useCallback(d => {
    if (d) {
      setFromDate(d);
    } else {
      setFromDate(today);
    }
  }, [today]);

  const handleRefresh = React.useCallback(() => {
    return fetchLogs()?.promise;
  }, [fetchLogs]);

  const downloadFile = React.useCallback((filename: string, file: Blob) => {
    const url = window.URL.createObjectURL(file);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
  }, []);

  const handleExport = React.useCallback(() => {
    if (locationGid) {
      setExportError(false);
      const fileResponse = log.export(mode, fromDate, plusOneWeek(fromDate), locationGid);
      fileResponse.then(res => {
        let filename = 'sessions.csv';
        const disposition = res.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        }
        const filePromise = res.blob();
        if (filePromise) {
          filePromise.then(
              file => downloadFile(filename, file),
              () => setExportError(true)
          );
        }
      });
    }
  }, [downloadFile, fromDate, locationGid, mode, plusOneWeek]);

  const handleResetSession = React.useCallback(() => {
    navigate(location.pathname);
  }, [location.pathname, navigate]);

  return (
    <TableWrapper>
      <HeaderControls left={
        sessionGid ?
          <div  className='flex-row session-info'>
            <div><strong>{ t('session') }</strong>: { sessionGid }</div>
            <Button onClick={ handleResetSession }>X</Button>
          </div>
          :
          <DateTimeInputBox dateOnly canBeInThePast label={ t('start-date') } value={ fromDate } onChange={ handleChangeFromDate } />
        }
        right={ sessionGid ? null : <Button error={ exportError } onClick={ handleExport }>{ t('export') }</Button> }
        refreshing={ loading }
        onRefresh={ handleRefresh }
      >
        { sessionGid ? null :
          <CalendarControls
            onToday={ handleToday }
            onBack={ handleBack }
            onForward={ handleForward }
          />
        }
      </HeaderControls>
      <Table
        columns={
          sessionGid ?
            [
              { propertyId: 'eventDate', captionKey: 'date', renderer: DateTimeWithSecondsRenderer },
              { propertyId: 'message', captionKey: 'message', align: 'left' },
            ]
            :
            [
              { propertyId: 'eventDate', captionKey: 'date', renderer: DateTimeWithSecondsRenderer },
              { propertyId: 'sessionGid', captionKey: 'session', renderer: SessionRenderer, className: 'nowrap' },
              { propertyId: 'message', captionKey: 'message', align: 'left' },
            ]
        }
        data={ logs?.data }
        noDataTextKey='no-data'
        rowCount={ logs?.count }
        pageSize={ 100 }
      />
    </TableWrapper>
  );
};

export default LogTable;
